import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../app/services/auth";
import { Spinner } from "../../components/UI/Spinner";

type FormData = {
  username: string;
  password: string;
};
export const Login = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [login, loginResult] = useLoginMutation();

  const { register, handleSubmit } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    login(data).then(({ data }: any) => {
      if (data !== undefined) {
        const token = data?.token;
        localStorage.setItem("token", token);
      } else {
        console.log("Invalid username or password");
      }
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (loginResult.isLoading) {
      setIsLoading(true);
    }
    if (loginResult.isError) {
      let errorResponse: any = loginResult.error;
      let errMsg = errorResponse?.data?.errorMessage;
      console.log(errMsg);

      toast.error(errMsg, {
        position: "top-center",
        autoClose: 1500,
      });

      loginResult.reset();
      setIsLoading(false);
    }

    if (loginResult.isSuccess) {
      console.log("here");
      toast.success("Login Successful", {
        position: "top-center",
        autoClose: 1500,
      });
      localStorage.setItem("token", loginResult.data?.token);
      navigate("/dashboard/users");
      loginResult.reset();
    }
  }, [loginResult, navigate]);
  return (
    <div className="h-screen">
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <h1 className="text-center text-3xl font-bold mb-10">
            Welcome to JOOMLACHI
          </h1>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  {...register("username")}
                  id="username"
                  name="username"
                  type="user"
                  autoComplete="user"
                  required
                  className="transition-all ease-in-out focus:scale-110 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                  placeholder="Username"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  {...register("password")}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="transition-all ease-in-out focus:scale-110 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary  focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <span className="font-medium text-primary hover:text-red-700">
                  Forgot your password?
                </span>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group transition-all ease-in-out shadow-md hover:-translate-y-1 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-primary hover:bg-red-700 x focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span> */}
                {isLoading ? <Spinner /> : "Sign In"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
