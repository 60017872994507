import { useEffect, useState } from "react";

import { Button, Form, Input, Select, Space } from "antd";

import { Option } from "antd/lib/mentions";
import {
  // useGetSellersQuery,
  useGetUsersQuery,
} from "../../../app/services/user";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { User } from "../../../types/user";
import { EditOrderPayload, Order } from "../../../types/order";
import { useGetProductsQuery } from "../../../app/services/product";
import { useGetAddressesQuery } from "../../../app/services/address";
import { useGetDiscountsQuery } from "../../../app/services/discount";
import { Discount } from "../../../types/discount";
import { Address } from "../../../types/address";

type EditOrderProps = {
  editOrder: (order: EditOrderPayload) => void;
  isCanceled: () => void;
  order: Order;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 20 },
};

export const EditOrder = (props: EditOrderProps) => {
  const [form] = Form.useForm();
  // const {
  //   data: sellersData,
  //   isFetching: isFetchingSellers,
  //   refetch: refetchSellers,
  // } = useGetSellersQuery({});

  const { data: productsData } = useGetProductsQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const { data: usersData } = useGetUsersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const { data: addressData } = useGetAddressesQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  // const {
  //   data: driverData,
  //   isFetching: isFetchingDriver,
  //   refetch: refetchDriver,
  // } = useGetDriversQuery({ limit: Number.MAX_SAFE_INTEGER + 1,});

  const { data: discountData } = useGetDiscountsQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const onFinish = async (values: any) => {
    let order = {
      buyer: values.buyer,
      // driver: values.driver,
      products: values.products,
      discountId: values.discountId,
      // driverInstruction: values.driverInstruction,
      // sellerInstruction: values.sellerInstruction,
      address: values.address,
      _id: props.order._id,
    };
    props.editOrder(order);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...props.order,
      address: props.order?.address?._id,
      discountId: props.order?.discount?._id,
      buyer: props.order?.buyer?._id,
      products: props.order.products.map((el) => {
        return {
          item: el.item?._id,
          quantity: el.quantity,
        };
      }),
    });
  }, [form, props.order]);

  const onChange = (value: string) => {
    console.log(value);
  };
  const onSearch = (value: string) => {
    console.log(value);
  };

  console.log(props.order);

  return (
    <>
      <Form
        onFinish={onFinish}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        {...layout}
      >
        <Form.Item
          label="Buyer"
          name="buyer"
          rules={[{ required: true, message: "Please input buyer!" }]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a buyer"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {usersData?.result?.map((user: User) => (
              <Option key={user._id} value={user._id}>
                {user.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* <Form.Item
          label="Seller"
          name="seller"
          rules={[{ required: true, message: "Please input seller!" }]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a seller"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {sellersData?.result?.map((seller: User) => (
              <Option key={seller._id} value={seller._id}>
                {seller.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        {/* <Form.Item
          label="Driver"
          name="driver"
          rules={[{ required: true, message: "Please input driver!" }]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a driver"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {driverData?.result?.map((driver: Driver) => (
              <Option key={driver._id} value={driver._id}>
                {driver.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        {/* <Form.Item
          label="Products"
          name="products"
          rules={[{ required: true, message: "Please input products!" }]}
        >
          <Select
            showSearch
            mode="multiple"
            style={{ width: 200 }}
            placeholder="Select products"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {productsData?.result?.map((product: any) => (
              <Option key={product._id} value={product._id}>
                {product.name}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="products"
          label="Select products "
          initialValue={[
            {
              item: undefined,
              quantity: 0,
            },
          ]}
        >
          <Form.List name="products">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "item"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing product",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select product"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {productsData?.result?.map((product: any) => (
                          <Option key={product._id} value={product._id}>
                            {product.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "quantity"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Quantity"
                        type="number"
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item label="Discount" name="discountId">
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a discount"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {discountData?.result?.map((discount: Discount) => (
              <Option key={discount._id} value={discount._id}>
                {discount.code}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* <Form.Item
          label="Total Price"
          name="totalPrice"
          rules={[{ required: true, message: "Please input total price!" }]}
        >
          <InputNumber min={0} />
        </Form.Item> */}

        {/* <Form.Item
          label="Driver Instruction"
          name="driverInstruction"
          rules={[
            { required: true, message: "Please input driver instruction!" },
          ]}
        >
          <Input />
        </Form.Item> */}

        {/* <Form.Item
          label="Seller Instruction"
          name="sellerInstruction"
          rules={[
            { required: true, message: "Please input seller instruction!" },
          ]}
        >
          <Input />
        </Form.Item> */}

        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: "Please input address!" }]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a address"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {addressData?.result?.map((address: Address) => (
              <Option key={address?._id} value={address?._id}>
                {address.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
