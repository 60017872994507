import { Addresses } from "../routes/dashboard/addresses/Addresses";
import { Admins } from "../routes/dashboard/admins/Admins";
import { Home } from "../routes/dashboard/home/Home";
import { Products } from "../routes/dashboard/products/Products";
import { Users } from "../routes/dashboard/users/Users";
import { Categories } from "../routes/dashboard/categories/Categories";

import { Settings } from "../routes/settings/Settings";
import { Discounts } from "../routes/dashboard/discounts/Discounts";
import { Drivers } from "../routes/dashboard/drivers/Drivers";
import { Orders } from "../routes/dashboard/orders/Orders";
import { Cities } from "../routes/dashboard/cities/Cities";
import { Advertisements } from "../routes/dashboard/advertisements/Advertisements";

export const privateRoutes = [
  {
    path: "",
    Element: Home,
  },
  {
    path: "users",
    Element: Users,
  },
  {
    path: "settings",
    Element: Settings,
  },
  {
    path: "admins",
    Element: Admins,
  },
  {
    path: "products",
    Element: Products,
  },
  {
    path: "addresses",
    Element: Addresses,
  },
  {
    path: "categories",
    Element: Categories,
  },
  {
    path: "discounts",
    Element: Discounts,
  },
  {
    path: "drivers",
    Element: Drivers,
  },
  {
    path: "orders",
    Element: Orders,
  },
  {
    path: "cities",
    Element: Cities,
  },
  {
    path: "advertisements",
    Element: Advertisements,
  },
];
