import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { ProductCard } from "./productCard";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";
import {
  useAddProductMutation,
  useDeleteProductMutation,
  useEditProductMutation,
  useGetProductsQuery,
} from "../../../app/services/product";
import { Avatar, message, Modal, Space } from "antd";
import AddProduct from "./addProduct";
import { AddProductPayload, Product } from "../../../types/product";
import { parseError } from "../../../utils/utils";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { User } from "../../../types/user";
import { Category } from "../../../types/category";
import Table, { ColumnsType } from "antd/lib/table";

export const Products = () => {
  interface ProductCellType {
    index: number;
    _id: string;
    category: Category;
    unit: string;
    unitNumber: number;
    stock: number;
    price: number;
    currency: string;
    description: string;
    name: string;
    seller: User;
    images: string[];
  }

  const columns: ColumnsType<ProductCellType> = [
    {
      title: "Action",
      key: "action",

      render: (record: ProductCellType) => (
        <>
          <Space className="mr-3">
            <DeleteOutlined
              key="delete"
              onClick={() => showDeleteConfirm(record._id)}
              style={{ color: "red" }}
            />
          </Space>
          <Space className="ml-3">
            <ProductCard
              product={record}
              edit={editProduct}
              editSuccess={isEditProductSuccess}
            />
          </Space>
        </>
      ),
      width: 100,
      fixed: "left",
    },

    {
      title: "index",
      dataIndex: "index",
      key: "index",
      width: 100,
    },
    {
      title: "images",
      dataIndex: "images",
      key: "images",
      render: (images: [string]) => <Avatar src={images[0]}></Avatar>,
    },

    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "category",
      dataIndex: "category",
      key: "category",
      render: (category: Category) => category?.name[0]?.value,
    },
    {
      title: "unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "stock",
      dataIndex: "stock",
      key: "stock",
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "description",
      dataIndex: "description",
      key: "description",
      width: 280,
    },

    {
      title: "seller",
      dataIndex: "seller",
      key: "seller",
      render: (seller: User) => seller?.fullName,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const { confirm } = Modal;
  const data: ProductCellType[] = [];

  const {
    isFetching: isFetchingData,
    data: productsData,
    refetch,
  } = useGetProductsQuery({
    page: currentPage,
    limit: 10,
  });

  const [
    editProduct,
    {
      isSuccess: isEditProductSuccess,
      isError: isEditProductError,
      error: editProductError,
      reset: resetEditProduct,
    },
  ] = useEditProductMutation();

  const [
    addProduct,
    {
      isLoading: isAddProductLoading,
      isSuccess: isAddProductSuccess,
      isError: isAddProductError,
      error: addProductError,
      reset: resetAddProduct,
    },
  ] = useAddProductMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [
    deleteProduct,
    {
      isSuccess: isDeleteProductSuccess,
      isError: isDeleteProductError,
      error: deleteProductError,
      reset: resetDeleteProduct,
    },
  ] = useDeleteProductMutation();

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this product?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        deleteProduct(id);
      },
      onCancel() {},
    });
  }

  useEffect(() => {
    if (isEditProductSuccess) {
      message.success("Product updated successfully");
      resetEditProduct();
    }
    if (isEditProductError) {
      message.error(parseError(editProductError));
      resetEditProduct();
    }
    if (isAddProductSuccess) {
      message.success("Product added successfully");
      resetAddProduct();
    }
    if (isAddProductError) {
      message.error(parseError(addProductError));
      resetAddProduct();
    }
    if (isDeleteProductSuccess) {
      message.success("Product deleted successfully");
      resetDeleteProduct();
    }
    if (isDeleteProductError) {
      message.error(parseError(deleteProductError));
      resetDeleteProduct();
    }
  }, [
    isDeleteProductSuccess,
    isEditProductSuccess,
    isAddProductSuccess,
    isAddProductError,
    isDeleteProductError,
    isEditProductError,
  ]);

  useEffect(() => {
    refetch();
    handleOk();
  }, [isEditProductSuccess, isAddProductSuccess, isDeleteProductSuccess]);

  return (
    <>
      <div className="  p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-h-4 fill-white" />
        </button>
      </div>
      {productsData?.result !== undefined &&
        productsData?.result.map((product: Product) => {
          data.push({
            index: data.length + 1,
            _id: product._id,
            category: product.category,
            unit: product.unit,
            stock: product.stock,
            price: product.price,
            currency: product.currency,
            description: product.description,
            name: product?.name,
            seller: product.seller,
            images: product.images,
            unitNumber: product.unitNumber,
          });
        })}

      <Table
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 2000, y: 450 }}
        loading={isFetchingData}
        pagination={false}
      />

      <Pagination
        itemsPerPage={10}
        totalItems={productsData?.count ? productsData.count : 20}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new Product"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddProduct
            isLoading={isAddProductLoading}
            addProduct={(Product: AddProductPayload) => addProduct(Product)}
            isSuccess={isAddProductSuccess}
            error={isAddProductError}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
