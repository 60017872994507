import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";

import { Image, message, Modal, Space, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { parseError } from "../../../utils/utils";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import {
  useAddAdvertisementMutation,
  useDeleteAdvertisementMutation,
  useEditAdvertisementMutation,
  useGetAdvertisementsQuery,
} from "../../../app/services/advertisement";
import {
  AddAdvertisementPayload,
  Advertisement,
  EditAdvertisementPayload,
} from "../../../types/advertisement";
import AddAdvertisement from "./AddAdvertisement";
import EditAdvertisement from "./EditAdvertisement";

export const Advertisements = () => {
  interface AdvertisementCellType {
    index: number;
    image: string;
    order: number;
    _id: string;
  }

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedAdvertisement, setSelectedAdvertisement] =
    useState<AdvertisementCellType | null>(null);
  const data: AdvertisementCellType[] = [];
  const columns: ColumnsType<AdvertisementCellType> = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Space className="mr-3">
            <DeleteOutlined
              key="delete"
              onClick={() => showDeleteConfirm(record._id)}
              style={{ color: "red" }}
            />
          </Space>
          <Space className="ml-3">
            <EditOutlined
              style={{ color: "#109DED" }}
              key="edit"
              onClick={() => {
                setSelectedAdvertisement(record);
                setEditModalVisible(true);
              }}
            />
          </Space>
        </>
      ),
      width: 100,
      fixed: "left",
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: 150,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (img: string) => <Image className="w-auto h-20" src={img} />,
    },
    {
      title: "order",
      dataIndex: "order",
      key: "order",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const { confirm } = Modal;
  // const [isEditModal, setisEditModal] = useState(false);

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this advertisement?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        deleteAdvertisement(id);
      },
      onCancel() {},
    });
  }
  const {
    isFetching: isFetchingAdvertisement,
    data: advertisementsData,
    refetch,
  } = useGetAdvertisementsQuery({
    page: currentPage,
    limit: 10,
  });

  const [
    editAdvertisement,
    {
      isLoading: isEditAdvertisementLoading,
      isSuccess: isEditAdvertisementSuccess,
      isError: isEditAdvertisementError,
      error: editAdvertisementError,
      reset: resetEditAdvertisement,
    },
  ] = useEditAdvertisementMutation();

  const [
    addAdvertisement,
    {
      isLoading: isAddAdvertisementLoading,
      isSuccess: isAddAdvertisementSuccess,
      isError: isAddAdvertisementError,
      error: addAdvertisementError,
      reset: resetAddAdvertisement,
    },
  ] = useAddAdvertisementMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [
    deleteAdvertisement,
    {
      isSuccess: isDeleteAdvertisementSuccess,
      isError: isDeleteAdvertisementError,
      error: deleteAdvertisementError,
      reset: resetDeleteAdvertisement,
    },
  ] = useDeleteAdvertisementMutation();

  useEffect(() => {
    if (isEditAdvertisementSuccess) {
      message.success("Advertisement updated successfully");
      resetEditAdvertisement();
    }
    if (isEditAdvertisementError) {
      message.error(parseError(editAdvertisementError));
      resetEditAdvertisement();
    }
    if (isAddAdvertisementSuccess) {
      message.success("Advertisement added successfully");
      resetAddAdvertisement();
    }
    if (isAddAdvertisementError) {
      message.error(parseError(addAdvertisementError));
      resetAddAdvertisement();
    }
    if (isDeleteAdvertisementSuccess) {
      message.success("Advertisement deleted successfully");
      resetDeleteAdvertisement();
    }
    if (isDeleteAdvertisementError) {
      message.error(parseError(deleteAdvertisementError));
      resetDeleteAdvertisement();
    }
  }, [
    isDeleteAdvertisementSuccess,
    isEditAdvertisementSuccess,
    isAddAdvertisementSuccess,
    isAddAdvertisementError,
    isEditAdvertisementError,
    isDeleteAdvertisementError,
  ]);

  useEffect(() => {
    refetch();
    handleOk();
  }, [
    isEditAdvertisementSuccess,
    isAddAdvertisementSuccess,
    isDeleteAdvertisementSuccess,
  ]);

  return (
    <>
      <div className="  p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-h-4 fill-white" />
        </button>
      </div>

      {advertisementsData?.result !== undefined &&
        advertisementsData?.result.map((advertisement: Advertisement) => {
          data.push({
            index: data.length + 1,
            _id: advertisement._id,
            image: advertisement.image,
            order: advertisement.order,
          });
        })}

      <Table
        scroll={{ x: 2000, y: 450 }}
        bordered
        columns={columns}
        pagination={false}
        dataSource={data}
        loading={isFetchingAdvertisement}
      />

      <Pagination
        itemsPerPage={10}
        totalItems={advertisementsData?.count ? advertisementsData.count : 10}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new Advertisement"
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddAdvertisement
            isLoading={isAddAdvertisementLoading}
            addAdvertisement={(advertisement: AddAdvertisementPayload) =>
              addAdvertisement(advertisement)
            }
            isSuccess={isAddAdvertisementSuccess}
            error={isAddAdvertisementError}
            isCanceled={handleCancel}
          />
        </Modal>
      )}

      <Modal
        title="Edit a new Advertisement"
        open={editModalVisible}
        onOk={() => setEditModalVisible(false)}
        onCancel={() => setEditModalVisible(false)}
        centered={true}
        footer={false}
      >
        <EditAdvertisement
          isLoading={isEditAdvertisementLoading}
          editAdvertisement={(advertisement: EditAdvertisementPayload) =>
            editAdvertisement(advertisement)
          }
          isSuccess={isEditAdvertisementSuccess}
          error={isEditAdvertisementError}
          advertisement={selectedAdvertisement as unknown as Advertisement}
          isCanceled={handleCancel}
          id={selectedAdvertisement?._id ?? ""}
        />
      </Modal>
    </>
  );
};
