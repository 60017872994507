import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { CategoryCard } from "./categoryCard";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";
import {
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useEditCategoryMutation,
  useGetCategoriesQuery,
} from "../../../app/services/category";

import { Avatar, message, Modal, Space, Table } from "antd";
import AddCategory from "./addCategory";
import {
  AddCategoryPayload,
  Category,
  Language,
} from "../../../types/category";
import { parseError } from "../../../utils/utils";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

export const Categories = () => {
  interface CategoryCellType {
    index: number;
    name: Language[];
    icon: string;
    order: number;
    _id: string;
  }

  const data: CategoryCellType[] = [];
  const columns: ColumnsType<CategoryCellType> = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Space className="mr-3">
            <DeleteOutlined
              key="delete"
              onClick={() => showDeleteConfirm(record._id)}
              style={{ color: "red" }}
            />
          </Space>
          <Space className="ml-3">
            <CategoryCard
              edit={editCategory}
              category={record}
              editSuccess={isEditCategorySuccess}
              editCategoryError={editCategoryError}
              editLoading={isEditCategoryLoading}
              editError={isEditCategoryError}
            ></CategoryCard>
          </Space>
        </>
      ),
      width: 100,
      fixed: "left",
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: Language[]) => name[0].value,
    },
    {
      title: "order",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "icon",
      dataIndex: "icon",
      key: "icon",
      render: (icon: string) => <Avatar src={icon} size={30} alt="icon" />,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const { confirm } = Modal;
  // const [isEditModal, setisEditModal] = useState(false);

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this category?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        deleteCategory(id);
      },
      onCancel() {},
    });
  }
  const {
    isFetching: isFetchingCategory,
    data: categoriesData,
    refetch,
  } = useGetCategoriesQuery({
    page: currentPage,
    limit: 10,
  });

  const [
    editCategory,
    {
      isLoading: isEditCategoryLoading,
      isSuccess: isEditCategorySuccess,
      isError: isEditCategoryError,
      error: editCategoryError,
      reset: resetEditCategory,
    },
  ] = useEditCategoryMutation();

  const [
    addCategory,
    {
      isLoading: isAddCategoryLoading,
      isSuccess: isAddCategorySuccess,
      isError: isAddCategoryError,
      error: addCategoryError,
      reset: resetAddCategory,
    },
  ] = useAddCategoryMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [
    deleteCategory,
    {
      isSuccess: isDeleteCategorySuccess,
      isError: isDeleteCategoryError,
      error: deleteCategoryError,
      reset: resetDeleteCategory,
    },
  ] = useDeleteCategoryMutation();

  useEffect(() => {
    if (isEditCategorySuccess) {
      message.success("Category updated successfully");
      resetEditCategory();
    }
    if (isEditCategoryError) {
      message.error(parseError(editCategoryError));
      resetEditCategory();
    }
    if (isAddCategorySuccess) {
      message.success("Category added successfully");
      resetAddCategory();
    }
    if (isAddCategoryError) {
      message.error(parseError(addCategoryError));
      resetAddCategory();
    }
    if (isDeleteCategorySuccess) {
      message.success("Category deleted successfully");
      resetDeleteCategory();
    }
    if (isDeleteCategoryError) {
      message.error(parseError(deleteCategoryError));
      resetDeleteCategory();
    }
  }, [
    isDeleteCategorySuccess,
    isEditCategorySuccess,
    isAddCategorySuccess,
    isAddCategoryError,
    isEditCategoryError,
    isDeleteCategoryError,
  ]);

  useEffect(() => {
    refetch();
    handleOk();
  }, [isEditCategorySuccess, isAddCategorySuccess, isDeleteCategorySuccess]);

  return (
    <>
      <div className="  p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-h-4 fill-white" />
        </button>
      </div>

      {categoriesData?.result !== undefined &&
        categoriesData?.result.map((category: Category) => {
          data.push({
            index: data.length + 1,
            _id: category._id,
            name: category.name,
            icon: category.icon,

            order: category.order,
          });
        })}

      <Table
        scroll={{ x: 2000, y: 450 }}
        bordered
        columns={columns}
        pagination={false}
        dataSource={data}
        loading={isFetchingCategory}
      />

      <Pagination
        itemsPerPage={10}
        totalItems={categoriesData?.count ? categoriesData.count : 20}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new Category"
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddCategory
            isLoading={isAddCategoryLoading}
            addCategory={(category: AddCategoryPayload) =>
              addCategory(category)
            }
            isSuccess={isAddCategorySuccess}
            error={isAddCategoryError}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
