import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "antd/dist/antd.css";
import EditCategory from "./editCategory";
import { Category, EditCategoryPayload } from "../../../types/category";

type CategoryCardProps = {
  category: Category;
  edit: (category: EditCategoryPayload) => void;
  editSuccess: boolean;
  editError: boolean;
  editLoading: boolean;
  editCategoryError: any;

  // edit: (user:string) => void;
};

export const CategoryCard = (props: CategoryCardProps) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (props.editSuccess === true) {
      handleOk();
    }
  }, [props.editSuccess]);

  return (
    <>
      <EditOutlined
        style={{ color: "#109DED" }}
        key="edit"
        onClick={showModal}
      />
      {visible === true && (
        <Modal
          title="Edit Category"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <EditCategory
            isLoading={props.editLoading}
            editCategory={(category: EditCategoryPayload) =>
              props.edit(category)
            }
            isSuccess={props.editSuccess}
            error={props.editError}
            category={props.category}
            isCanceled={handleCancel}
            id={props.category._id}
          />
        </Modal>
      )}
    </>
  );
};
