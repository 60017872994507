import { baseApi } from "./baseApi";
import { Category, AddCategoryPayload, EditCategoryPayload } from "../../types/category";
import qs from "query-string";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { PaginatedResponse } from "../../types/PaginatedResponse";

export const categoryApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCategories: build.query<PaginatedResponse<Category>, PaginatedPayload<Category>>({
            query: (payload) => ({
                url: `categories?${qs.stringify(payload)}`,
                method: "GET",
            }),
        }),
    
        getCategory: build.query<Category, string>({
            query: (id) => ({
                url: `categories/${id}`,
                method: "GET",
            }),
        }),

        addCategory: build.mutation<Category, AddCategoryPayload>({
            query: (payload) => ({
                url: `categories`,
                method: "POST",
                body: payload,
            }),
        }),

        editCategory: build.mutation<Category, EditCategoryPayload>({
            query: (payload) => ({
                url: `categories/${payload._id}`,
                method: "PATCH",
                body: {
                    name: payload.name,
                    order: payload.order,
                    icon: payload.icon,
                },
            }),
        }),
        
        deleteCategory: build.mutation<Category, string>({
            query: (id) => ({
                url: `categories/${id}`,
                method: "DELETE",
            }),

        }),
    }),
});

export const {
    useGetCategoriesQuery,
    useGetCategoryQuery,
    useAddCategoryMutation,
    useEditCategoryMutation,
    useDeleteCategoryMutation,
} = categoryApi;

