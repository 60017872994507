import { Form, Input, Spin, Space } from "antd";
import { useEffect } from "react";

import { City, EditCityPayload } from "../../../types/city";

type EditCityProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  editCity: (user: EditCityPayload) => void;
  isCanceled: () => void;
  city?: City;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const EditCity = (props: EditCityProps): JSX.Element => {
  const onFinish = async (values: any) => {
    props.editCity({
      name: values.name,
      _id: props.city?._id ?? "",
    });
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.city);
  }, [props.city, form]);

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="name"
          label="City Name"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="name">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Value"
                      />
                    </Form.Item>
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item className="flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default EditCity;
