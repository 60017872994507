import { baseApi } from "./baseApi";
import {
  AddAddressPayload,
  Address,
  EditAddressPayload,
} from "../../types/address";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import qs from "query-string";

export const addressApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAddresses: build.query<
      PaginatedResponse<Address>,
      PaginatedPayload<Address>
    >({
      query: (payload) => ({
        url: `addresses?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    getAddress: build.query<Address, string>({
      query: (id) => ({
        url: `addresses/${id}`,
        method: "GET",
      }),
    }),

    addAddress: build.mutation<Address, AddAddressPayload>({
      query: (payload) => ({
        url: `addresses`,
        method: "POST",
        body: payload,
      }),
    }),

    editAddress: build.mutation<Address, EditAddressPayload>({
      query: (payload) => ({
        url: `addresses/${payload._id}`,
        method: "PATCH",
        body: {
          coordinates: payload.coordinates,
          description: payload.description,
          title: payload.title,
          user: payload.user,
        },
      }),
    }),

    deleteAddress: build.mutation<Address, string>({
      query: (id) => ({
        url: `addresses/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAddressesQuery,
  useGetAddressQuery,
  useAddAddressMutation,
  useEditAddressMutation,
  useDeleteAddressMutation,
} = addressApi;
