import qs from "qs";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import {
  Driver,
  AddDriverPayload,
  EditDriverPayload,
} from "../../types/driver";
import { baseApi } from "./baseApi";
import { PaginatedResponse } from "../../types/PaginatedResponse";

export const driverApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDrivers: build.query<
      PaginatedResponse<Driver>,
      PaginatedPayload<Driver>
    >({
      query: (payload) => ({
        url: `drivers?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    getDriver: build.query<Driver, string>({
      query: (id) => ({
        url: `drivers/${id}`,
        method: "GET",
      }),
    }),

    addDriver: build.mutation<Driver, AddDriverPayload>({
      query: (payload) => ({
        url: `drivers`,
        method: "POST",
        body: payload,
      }),
    }),

    editDriver: build.mutation<Driver, EditDriverPayload>({
      query: (payload) => ({
        url: `drivers/${payload._id}`,
        method: "PATCH",
        body: {
          fullName: payload.fullName,
          phoneNumber: payload.phoneNumber,
          profilePicture: payload.profilePicture,
          seller: payload.seller,
        },
      }),
    }),

    deleteDriver: build.mutation<Driver, string>({
      query: (id) => ({
        url: `drivers/admin/${id}`,
        method: "DELETE",
      }),
    }),

    getDriverBySeller: build.query<Driver, string>({
      query: (seller) => ({
        url: `drivers/seller/${seller}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetDriversQuery,
  useGetDriverQuery,
  useAddDriverMutation,
  useEditDriverMutation,
  useDeleteDriverMutation,
  useGetDriverBySellerQuery,
} = driverApi;
