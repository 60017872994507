import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "antd/dist/antd.css";
import { Address, EditAddressPayload } from "../../../types/address";
import EditAddress from "./editAddress";

type AddressCardProps = {
  addressData: Address;
  edit: (address: EditAddressPayload) => void;
  editSuccess: boolean;
};

export const AddressCard = (props: AddressCardProps) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
  }, [props.editSuccess]);
  return (
    <>
      <EditOutlined
        style={{ color: "#109DED" }}
        key="edit"
        onClick={showModal}
      ></EditOutlined>
      {visible === true && (
        <Modal
          title="Edit admin"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <EditAddress
            editAddress={(address: EditAddressPayload) => props.edit(address)}
            addressData={props.addressData}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
