import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <div className="flex flex-col justify-center text-center gap-5">
        <h1 className="text-3xl">404</h1>
        <span className="text-5xl">Page not found</span>
        <p className="text-3xl text-gray-400">
          we could'nt find the page you looking for.
        </p>
        <Link className="text-blue-500" to={"/"}>
          Go back home {` >`}
        </Link>
      </div>
    </div>
  );
};
