import { baseApi } from "./baseApi";
import { Setting, EditSetting, AddSetting } from "../../types/setting";
import qs from "query-string";

export const settingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSettings: build.query({
      query: (payload) => ({
        url: `settings?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    getSetting: build.query<Setting, string>({
      query: (id) => ({
        url: `settings/${id}`,
        method: "GET",
      }),
    }),

    addSetting: build.mutation<Setting, AddSetting>({
      query: (payload) => ({
        url: `settings`,
        method: "POST",
        body: payload,
      }),
    }),

    editSetting: build.mutation<Setting, EditSetting>({
      query: (payload) => ({
        url: `settings/${payload._id}`,
        method: "PATCH",
        body: {
          USD_IQDExchange: payload.USD_IQDExchange,
          IQD_USDExchange: payload.IQD_USDExchange,
        },
      }),
    }),

    // deleteSetting: build.mutation<Setting, string>({
    //     query: (id) => ({
    //         url: `settings/${id}`,
    //         method: "DELETE",
    //     }),

    // }),
  }),
});

export const {
  useGetSettingsQuery,
  useGetSettingQuery,
  useAddSettingMutation,
  useEditSettingMutation,
  // useDeleteSettingMutation,
} = settingApi;
