import { Discount, AddDiscountPayload } from "../../../types/discount";
import { useState } from "react";
import { Checkbox, DatePicker, Form, Input, Select, Spin } from "antd";
import { Category } from "../../../types/category";
import { useGetCategoriesQuery } from "../../../app/services/category";
import {
  useGetSellersQuery,
  useGetUsersQuery,
} from "../../../app/services/user";
import { Option } from "antd/lib/mentions";
import { User } from "../../../types/user";
import moment from "moment";
import { useGetProductsQuery } from "../../../app/services/product";
import { Product } from "../../../types/product";

type AddDiscountProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addDiscount: (category: AddDiscountPayload) => void;
  isCanceled: () => void;
  discount?: Discount;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const AddDiscount = (props: AddDiscountProps): JSX.Element => {
  const onChange = (value: string) => {
    console.log(value);
  };
  const onSearch = (value: string) => {
    console.log(value);
  };

  const [discountDate, setDiscountDate] = useState("");

  const [form] = Form.useForm();

  const { data: categoriesData } = useGetCategoriesQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const { data: productsData } = useGetProductsQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const { data: sellersData } = useGetSellersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });
  const { data: usersData } = useGetUsersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const onFinish = async (values: any) => {
    console.log(values);

    props.addDiscount({
      code: values.code,
      amount: parseInt(values.amount.toString()),
      currency: values.currency,
      isPercentage: values.isPercentage ?? false,
      expiryDate: moment(values.expiryDate).format("YYYY-MM-DD"),
      numberOfDiscounts: parseInt(values.numberOfDiscounts.toString()),
      products: values.products,
      categories: values.categories,
      sellers: values.sellers,
      applyToAll: values.applyToAll ?? false,
      users: values.users,
      public: values.public ?? false,
    });
  };

  const dateOnChange = (dateString: any) => {
    setDiscountDate(dateString);
    console.log(dateString);
    console.log("date:" + discountDate);
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
        name="basic"
        initialValues={{ remember: true }}
      >
        <Form.Item label="Code" name="code" rules={[{ required: true }]}>
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
          <Input className="w-60 rounded-full" />
        </Form.Item>

        <Form.Item
          label="is Percentage"
          name="isPercentage"
          valuePropName="checked"
        >
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item
          label="Apply to all"
          name="applyToAll"
          valuePropName="checked"
        >
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item label="public" name="public" valuePropName="checked">
          <Checkbox></Checkbox>
        </Form.Item>

        <Form.Item
          label="End Date"
          name="expiryDate"
          rules={[{ required: true }]}
        >
          <DatePicker
            onChange={dateOnChange}
            className="rounded-full"
            format="DD/MM/YYYY"
          />
        </Form.Item>

        <Form.Item
          label="Number of discounts"
          name="numberOfDiscounts"
          rules={[{ required: true }]}
        >
          <Input type="number" className="w-60 rounded-full" />
        </Form.Item>

        <Form.Item label="Category" name="categories">
          <Select
            showSearch
            mode="multiple"
            placeholder="Select a category"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {categoriesData?.result?.map((category: Category) => (
              <Option className="rounded-full" value={category._id}>
                {category.name[0].value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Products" name="products">
          <Select
            showSearch
            mode="multiple"
            placeholder="Select products"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {productsData?.result?.map((product: Product) => (
              <Option className="rounded-full" value={product._id}>
                {product.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Sellers" name="sellers">
          <Select
            mode="multiple"
            showSearch
            placeholder="Select sellers"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {sellersData?.result?.map((seller: User) => (
              <Option className="rounded-full" value={seller._id}>
                {seller.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Users" name="users">
          <Select
            mode="multiple"
            showSearch
            placeholder="Select users"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {usersData?.result?.map((user: User) => (
              <Option className="rounded-full" value={user._id}>
                {user.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Currency:"
          name="currency"
          rules={[{ required: true, message: "Please input your currency!" }]}
        >
          <Select
            placeholder="Select a currency"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option className="rounded-full" value="usd">
              USD
            </Option>
            <Option className="rounded-full" value="iqd">
              IQD
            </Option>
          </Select>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default AddDiscount;
