import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const ProtectedRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const location = useLocation();
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL ?? "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsLoading(false);
        res.status === 200 && setIsAuthorized(true);
      })
      .catch((e) => {
        setIsLoading(false);
        setIsAuthorized(false);
      });
  }, [location.pathname, token]);
  if (isLoading) return <div>loading ...</div>;
  if (isAuthorized) return <Outlet />;
  return <Navigate to="/login" replace />;
};
