import { Form, Input, Spin, Select } from "antd";

import { AddAddressPayload } from "../../../types/address";
import { useGetUsersQuery } from "../../../app/services/user";
import { Option } from "antd/lib/mentions";
import { User } from "../../../types/user";
import TextArea from "antd/lib/input/TextArea";

type AddAddressProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addAddress: (admin: AddAddressPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const AddAddress = (props: AddAddressProps): JSX.Element => {
  const onFinish = async (values: any) => {
    props.addAddress({
      coordinates: {
        longitude: values.longitude,
        latitude: values.latitude,
      },
      description: values.description,
      title: values.title,
      user: values.user,
    });
  };

  const { data: usersData } = useGetUsersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const onChange = (value: string) => {
    console.log(value);
  };

  const onSearch = (value: string) => {
    console.log(value);
  };
  const [form] = Form.useForm();

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Longitude: "
          name="longitude"
          rules={[{ message: "Coordinates is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>

        <Form.Item
          label="Latitude: "
          name="latitude"
          rules={[{ message: "Coordinates is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Title: "
          name="title"
          rules={[{ required: true, message: "Title is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Description: "
          name="description"
          rules={[{ message: "Description is required" }]}
        >
          <TextArea className="w-60 rounded-full" />
        </Form.Item>

        <Form.Item
          label="User: "
          name="user"
          className="address"
          rules={[{ required: true, message: "User is required" }]}
        >
          <Select
            showSearch
            placeholder="Select a user"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {usersData?.result?.map((user: User) => (
              <Option className="rounded-full" value={user._id}>
                {user.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item className=" flex flex-col ">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default AddAddress;
