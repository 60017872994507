import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import {
  useGetSettingsQuery,
  useEditSettingMutation,
  useAddSettingMutation,
} from "../../app/services/setting";
import { Setting } from "../../types/setting";

export const Settings = () => {
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 7 },
  };

  const { data: settingsData, refetch } = useGetSettingsQuery({});
  const [
    editSetting,
    { isSuccess: isEditSettingSuccess, isError: isEditSettingError },
  ] = useEditSettingMutation();

  const [
    addSetting,
    { isSuccess: isAddSettingSuccess, isError: isAddSettingError },
  ] = useAddSettingMutation();

  const onFinish = async (values: Setting) => {
    console.log(values);
    if (settingsData._id)
      editSetting({
        USD_IQDExchange: +values?.USD_IQDExchange?.toString(),
        IQD_USDExchange: +values?.IQD_USDExchange?.toString(),
        _id: settingsData._id,
      });
    else
      addSetting({
        USD_IQDExchange: +values?.USD_IQDExchange?.toString(),
        IQD_USDExchange: +values?.IQD_USDExchange?.toString(),
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    console.log(settingsData);
    if (settingsData) {
      form.setFieldsValue({
        USD_IQDExchange: settingsData?.USD_IQDExchange,
        IQD_USDExchange: settingsData?.IQD_USDExchange,
        _id: settingsData?._id,
      });
    }
  }, [settingsData]);

  useEffect(() => {
    if (isEditSettingSuccess || isAddSettingSuccess) {
      message.success("Settings updated successfully");
      refetch();
    }

    if (isEditSettingError || isAddSettingError) {
      message.error("Error updating settings");
    }
  }, [
    isEditSettingSuccess,
    isEditSettingError,
    isAddSettingSuccess,
    isAddSettingError,
  ]);
  return (
    <>
      <Form
        name="settings"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        {...layout}
      >
        <div className="shadow sm:overflow-hidden bg-white text-sm py-2 px-4">
          <div className="space-y-6 bg-white px-4  sm:p-6">
            <Form.Item
              name="USD_IQDExchange"
              label="USD Exchange Rate"
              className="mt-10"
              rules={[{ required: true, message: "Please Enter" }]}
            >
              <Input
                type="number"
                className="border-2 border-slate-300 shadow-sm  flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                placeholder="Todays USD Exchange Rate"
              />
            </Form.Item>

            <Form.Item
              name="IQD_USDExchange"
              label="IQD Exchange Rate"
              rules={[{ required: true, message: "Please Enter" }]}
            >
              <Input
                type="number"
                className="border-2 border-slate-300 shadow-sm  flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                placeholder="Today's IQD Exchange Rate"
              />
            </Form.Item>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <Button
              type="primary"
              htmlType="submit"
              className="inline-flex justify-center rounded-full border border-transparent bg-primary  text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};
