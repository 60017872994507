import { Modal, Select, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

import {
  useCompleteOrderMutation,
  useDeliverOrderMutation,
} from "../../../app/services/order";

const statuses = ["delivering", "completed"];

export const StatusCell = ({
  value,
  orderId,
  refetch,
  isCanceled,
}: {
  value: string;
  orderId: string;
  refetch: () => void;
  isCanceled: boolean;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [completeOrder, completeOrderResult] = useCompleteOrderMutation();
  const [deliverOrder, deliverResult] = useDeliverOrderMutation();
  const [status, setStatus] = useState();
  const getColor = () => {
    switch (value) {
      case "canceled":
        return "red";

      case "pending":
        return "yellow";

      case "approved":
        return "blue";

      case "delivering":
        return "orange";

      case "completed":
        return "green";

      default:
        return "";
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (value: string) => {
    console.log(value);
    if (value === "completed") completeOrder(orderId);
    else deliverOrder(orderId);
  };

  useEffect(() => {
    if (completeOrderResult.isSuccess) toast.success("order completed");
    else if (deliverResult.isSuccess) toast.success("order delivered");

    refetch();
  }, [completeOrderResult.isSuccess, deliverResult.isSuccess]);

  return (
    <div className="flex items-center">
      <Tag color={getColor()}>{value}</Tag>
      <EditOutlined
        onClick={() => showModal()}
        style={{ color: "#109DED" }}
        key="edit"
        className={`cursor-pointer ${isCanceled && "hidden"}`}
      />

      <Modal
        title="Add Order"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered={true}
      >
        <label>Change status : </label>
        <Select
          showSearch
          placeholder="Change status"
          optionFilterProp="children"
          onChange={onChange}
          defaultValue={value}
          className="rounded-full w-7/12"
          // filterOption={(input, option) =>
          //   (option!.children as unknown as string)
          //     .toLowerCase()
          //     .includes(input.toLowerCase())
          // }
        >
          {statuses.map((el, i) => {
            return (
              <Select.Option key={el} value={el}>
                {el}
              </Select.Option>
            );
          })}
        </Select>
        <div>
          {(completeOrderResult.isLoading || deliverResult.isLoading) && (
            <Spin />
          )}
        </div>
      </Modal>
    </div>
  );
};
