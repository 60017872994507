import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Discount, EditDiscountPayload } from "../../../types/discount";
import EditDiscount from "./editDiscount";
import { EditOutlined } from "@ant-design/icons";

type DiscountsProps = {
  editDiscount: (discount: EditDiscountPayload) => void;
  editSuccess: boolean;
  discount: Discount;
};

const DiscountCard = (props: DiscountsProps): JSX.Element => {
  const [visible, setVisible] = useState(false);
  // const [discountExpireyDate, setDiscountExpireyDate] = useState("");

  const showModal = () => {
    setVisible(true);
    console.log("showModal");
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
  }, [props.editSuccess]);

  // function formatDate() {
  //   if (props.discount?.expiryDate) {
  //     const date = new Date(props.discount?.expiryDate);
  //     const month = date.getMonth() + 1;
  //     const day = date.getDate();
  //     const year = date.getFullYear();
  //     const formattedDate = `${day}-${month}-${year}`;
  //     setDiscountExpireyDate(formattedDate);
  //   }
  // }

  // useEffect(() => {
  //   formatDate();
  // }, [props.discount]);

  return (
    <>
      <EditOutlined
        style={{ color: "#109DED" }}
        key="edit"
        onClick={showModal}
      ></EditOutlined>
      {visible && (
        <Modal
          title="Edit Discount"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <EditDiscount
            editDiscount={props.editDiscount}
            discount={props.discount}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};

export default DiscountCard;
