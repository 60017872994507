import { baseApi } from "./baseApi";
import {
  User,
  AddUserPayload,
  ResetPassword,
  EditUserPayload,
} from "../../types/user";
import qs from "query-string";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { PaginatedResponse } from "../../types/PaginatedResponse";

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<PaginatedResponse<User>, PaginatedPayload<User>>({
      query: (payload) => ({
        url: `users?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    getUser: build.query<User, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: "GET",
      }),
    }),

    addUser: build.mutation<User, AddUserPayload>({
      query: (payload) => ({
        url: `users`,
        method: "POST",
        body: payload,
      }),
    }),

    getSellers: build.query<PaginatedResponse<User>, PaginatedPayload<User>>({
      query: (payload) => ({
        url: `users/sellers?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    editUser: build.mutation<User, EditUserPayload>({
      query: (payload) => ({
        url: `users/${payload._id}`,

        method: "PATCH",
        body: {
          fullName: payload.fullName,
          phoneNumber: payload.phoneNumber,
          city: payload.city,
          email: payload.email,
          profilePicture: payload.profilePicture,
          roles: payload.roles,
          isVerified: payload.isVerified,
          isSeller: payload.isSeller,
          isRegistered: payload.isRegistered,
        },
      }),
    }),

    deleteUser: build.mutation<User, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
    }),

    resetPassword: build.mutation<User, ResetPassword>({
      query: (payload) => ({
        url: `users/reset-password`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useGetSellersQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useResetPasswordMutation,
} = userApi;
