import { useEffect, useState } from "react";

import { Form, Input, Select } from "antd";

import { Address, EditAddressPayload } from "../../../types/address";
import { useGetUsersQuery } from "../../../app/services/user";
import { User } from "../../../types/user";
import { Option } from "antd/lib/mentions";
import TextArea from "antd/lib/input/TextArea";

type EditAddressProps = {
  editAddress: (address: EditAddressPayload) => void;
  isCanceled: () => void;
  addressData: Address;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const EditAddress = (props: EditAddressProps): JSX.Element => {
  const { data: usersData } = useGetUsersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const [initialValues, setInitialValues] = useState<any>();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    let address = {
      _id: props.addressData?._id,
      coordinates: values.coordinates,
      description: values.description,
      titles: values.titles,
      user: values.user,
    };

    props.editAddress(address);
  };

  useEffect(() => {
    if (props.addressData) {
      setInitialValues({
        coordinates: props.addressData.coordinates,
        description: props.addressData.description,
        titles: props.addressData.title,
        // user: props.addressData.user,
        user: props.addressData.user?._id,
      });
    }
  }, [props.addressData]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const onChange = (value: string) => {
    console.log(value);
  };

  const onSearch = (value: string) => {
    console.log(value);
  };

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Longitude: "
          name={["coordinates", "longitude"]}
          rules={[{ message: "Coordinates is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>

        <Form.Item
          label="Latitude: "
          name={["coordinates", "latitude"]}
          rules={[{ message: "Coordinates is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Description: "
          name="description"
          rules={[{ message: "Description is required" }]}
        >
          <TextArea className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Titles: "
          name="titles"
          rules={[{ message: "Titles is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="User: "
          name="user"
          className="address"
          rules={[{ required: true, message: "User is required" }]}
        >
          <Select
            showSearch
            placeholder="Select a user"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {usersData?.result?.map((user: User) => (
              <Option className="rounded-full" value={user._id}>
                {user.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item className=" flex flex-col ">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditAddress;
