import { useEffect, useState } from "react";

import {
  Form,
  Input,
  Button,
  Upload,
  message,
  Select,
  Radio,
  RadioChangeEvent,
} from "antd";
import { Option } from "antd/lib/mentions";
import { useGetCitiesQuery } from "../../../app/services/city";
import { City } from "../../../types/city";
import { EditUserPayload, User } from "../../../types/user";

type EditUserProps = {
  editUser: (user: EditUserPayload) => void;
  isCanceled: () => void;
  userData: User;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const EditUser = (props: EditUserProps): JSX.Element => {
  const options = [
    { label: "Is Buyer", value: "isBuyer" },
    { label: "Is Seller", value: "isSeller" },
    // { label: "Seller and Buyer", value: "isSellerAndBuyer" },
  ];

  const { data: citiesData } = useGetCitiesQuery({
    page: 1,
    limit: 10000000,
  });

  const token = localStorage.getItem("token");
  const [userType, setUserType] = useState("isBuyer");

  const [initialValues, setInitialValues] = useState<any>();
  // const [image, setImage] = useState("");
  const [previewsImage, setPreviewImage] = useState<{
    url?: string;
    uid?: string;
  }>();

  const onFinish = async (values: any) => {
    let profilePicture = values?.profilePicture[0]?.response?.url;
    if (!profilePicture) profilePicture = previewsImage?.url;

    let isSeller = false;
    if (userType === "isBuyer") {
      // values.isBuyer = true;
      isSeller = false;
      console.log("user is buyer");
    }
    if (userType === "isSeller") {
      isSeller = true;
      console.log("user is seller");
      // values.isBuyer = false;
    }

    values.isVerified === "verified"
      ? (values.isVerified = true)
      : (values.isVerified = false);

    if (values?.profilePicture[0]?.response) {
      let profilePicture = values?.profilePicture[0].response[0].Location;
      let user = {
        _id: props.userData._id,
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        profilePicture: profilePicture,
        city: values.city,
        isSeller: isSeller,
        isVerified: values.isVerified,
        roles: isSeller ? ["SELLER"] : ["BUYER"],
      };
      props.editUser(user);
    } else {
      let user = {
        _id: props.userData._id,
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        profilePicture: previewsImage?.url,
        city: values.city,
        isSeller: isSeller,
        isVerified: values.isVerified,
        roles: isSeller ? ["SELLER"] : ["BUYER"],
      };
      props.editUser(user);
    }
  };

  console.log(initialValues);

  useEffect(() => {
    if (props.userData) {
      console.log(props.userData?.roles.includes("SELLER"));
      if (props.userData?.roles.includes("SELLER")) {
        setUserType("isSeller");
        setInitialValues({
          userRole: "isSeller",
          userType: "isSeller",
        });
      }
      setInitialValues({
        ...initialValues,
        email: props.userData?.email,
        fullName: props.userData?.fullName,
        password: props.userData?.password,
        city: props.userData?.city?._id,
        phoneNumber: props.userData?.phoneNumber,
        // roles: props.userData?.roles,
        verified: props.userData?.isVerified,
        profilePicture: [
          {
            url: props.userData?.profilePicture,
            uid: "23234fdf",
            name: props.userData?.profilePicture,
          },
        ],

        isSeller: props.userData?.isSeller,
        isRegistered: props.userData?.isRegistered,
      });

      setPreviewImage({
        url: props.userData.profilePicture,
      });
    }
  }, [props.userData]);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  const onChange1 = ({ target: { value } }: RadioChangeEvent) => {
    setUserType(value);
    form.setFieldsValue({ userRole: value, userType: value });
  };

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Full name: "
          name="fullName"
          rules={[{ required: true, message: "Full name is required" }]}
        >
          <Input
            className="w-60 rounded-full"
            defaultValue={props.userData?.fullName}
          />
        </Form.Item>
        <Form.Item
          label="Email: "
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input
            className="w-60 rounded-full"
            type="email"
            defaultValue={props.userData?.email}
          />
        </Form.Item>
        <Form.Item
          label="Phone Number: "
          name="phoneNumber"
          rules={[{ required: true, message: "phoneNumber is required" }]}
        >
          <Input
            className="w-60 rounded-full"
            defaultValue={props.userData?.phoneNumber}
          />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: "City is required" }]}
        >
          <Select
            showSearch
            placeholder="Select a city"
            optionFilterProp="children"
            // onChange={onChange}
            // onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {citiesData?.result?.map((city: City) => (
              <Option className="rounded-full" value={city._id}>
                {city.name[0].value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Choose Role"
          name="userRole"
          rules={[{ required: true, message: "roles is required" }]}
          valuePropName="checked"
          initialValue={userType}
        >
          <Radio.Group
            defaultValue={userType}
            value={userType}
            options={options}
            style={{ color: "red" }}
            onChange={onChange1}
          />
        </Form.Item>

        <Form.Item
          label="Is user verified"
          name="isVerified"
          initialValue={props?.userData?.isVerified ? "verified" : "uncheck"}
        >
          <Radio.Group>
            <Radio value="verified">Is verified</Radio>
            <Radio value="uncheck">Unverify</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Picture is required" }]}
          name="profilePicture"
          label="Picture:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={"https://api.joomlachi.potan.co/upload"}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button className="w-60 rounded-full hover:text-primary hover:border-primary">
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditUser;
