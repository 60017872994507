import { useEffect, useState } from "react";

import { Form, Input } from "antd";
import PasswordChecklist from "react-password-checklist";

import { Admin, EditAdminPayload } from "../../../types/admin";

type EditAdminProps = {
  editAdmin: (user: EditAdminPayload) => void;
  isCanceled: () => void;
  adminData: Admin;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const EditAdmin = (props: EditAdminProps): JSX.Element => {
  const [passwordValid, setPasswordValid] = useState("");

  const [initialValues, setInitialValues] = useState<any>();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    props.editAdmin({
      _id: props.adminData._id,
      username: values.username,
      email: values.email,
      password: values.password,
      roles: ["ADMIN"],
      isAdmin: true,
      isActive: true,
    });
  };

  useEffect(() => {
    if (props.adminData) {
      setInitialValues({
        username: props.adminData.username,
        email: props.adminData.email,
        password: props.adminData.password,
        roles: ["ADMIN"],
        isAdmin: true,
        isActive: true,
      });
    }
  }, [props.adminData]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Username: "
          name="username"
          rules={[{ message: "Username is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Email: "
          name="email"
          rules={[
            { message: "Email is required" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input type="email" className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Password: "
          name="password"
          rules={[{ message: "Password is required" }]}
        >
          <Input.Password
            className="w-60 rounded-full text-sm"
            type="password"
            onChange={(e) => setPasswordValid(e.target.value)}
            autoComplete="off"
          />
        </Form.Item>{" "}
        <PasswordChecklist
          rules={["minLength"]}
          minLength={8}
          value={passwordValid}
          style={{ marginBottom: "1rem" }}
        />
        <Form.Item className=" flex flex-col ">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditAdmin;
