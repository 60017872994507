import { Route, Routes, useLocation } from "react-router-dom";
import { privateRoutes } from "../../app/private-routes";
import { ProtectedRoutes } from "../../app/ProtectRoutes";
import SideBar from "../../components/Sidebar";

export const Container = () => {
  const location = useLocation();
  return (
    <div className="flex flex-row bg-white max-h-screen">
      <SideBar currentRoute={location.pathname.substring(1)} />
      <div className="bg-lightGray shadow-md overflow-hidden rounded-2xl my-10 mx-3 flex-1  flex flex-col ">
        {/* <div>
          <div
            className=" max-full"
            style={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1486025402772-bc179c8dfb0e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.75)",
              }}
              className="font-normal py-7 max-full  px-7 text-xl border-b "
            >
              {location.pathname.split("/").map((el, index) => {
                const isLast =
                  index === location.pathname.split("/").length - 1;
                return isLast ? (
                  <span className="text-white">{el}</span>
                ) : (
                  <Link className="text-white" to={`/${el}`} replace>
                    {el}/
                  </Link>
                );
              })}
            </div>
          </div>
        </div> */}
        <div className="overflow-y-auto  min-h-full ">
          <Routes>
            <Route element={<ProtectedRoutes />}>
              {privateRoutes.map(({ path, Element }, index) => {
                return <Route key={index} path={path} element={<Element />} />;
              })}
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
};
