import { useState } from "react";

import { Form, Input, Spin } from "antd";
import PasswordChecklist from "react-password-checklist";

import { AddAdminPayload } from "../../../types/admin";

type AddAdminProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addAdmin: (admin: AddAdminPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const AddAdmin = (props: AddAdminProps): JSX.Element => {
  const [passwordValid, setPasswordValid] = useState("");

  const onFinish = async (values: any) => {
    props.addAdmin({
      username: values.username,
      email: values.email,
      password: values.password,
      roles: ["ADMIN"],
      isAdmin: true,
      isActive: true,
    });
  };

  const [form] = Form.useForm();

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Username: "
          name="username"
          rules={[{ required: true, message: "Username is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Email: "
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input className="w-60 rounded-full" type="email" />
        </Form.Item>
        <Form.Item
          label="Password: "
          name="password"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input.Password
            className="w-60 rounded-full text-sm"
            type="password"
            onChange={(e) => setPasswordValid(e.target.value)}
            autoComplete="off"
          />
        </Form.Item>{" "}
        <PasswordChecklist
          rules={["minLength"]}
          minLength={8}
          value={passwordValid}
          style={{ marginBottom: "1rem" }}
        />
        <Form.Item className=" flex flex-col ">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default AddAdmin;
