import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "antd/dist/antd.css";
import { EditOrder } from "./EditOrder";
import { EditOrderPayload, Order } from "../../../types/order";

type OrderCardProps = {
  order: Order;
  edit: (user: EditOrderPayload) => void;
  editSuccess: boolean;

  // edit: (user:string) => void;
};

export const OrderCard = (props: OrderCardProps) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
  }, [props.editSuccess]);
  return (
    <>
      <EditOutlined
        style={{ color: "#109DED" }}
        key="edit"
        onClick={showModal}
      />
      {visible === true && (
        <Modal
          title="Edit order"
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <EditOrder
            editOrder={(user: EditOrderPayload) => props.edit(user)}
            order={props.order}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
