import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "antd/dist/antd.css";
import { Admin, EditAdminPayload } from "../../../types/admin";
import EditAdmin from "./editAdmin";

type AdminCardProps = {
  adminData: Admin;
  edit: (admin: EditAdminPayload) => void;
  editSuccess: boolean;
};

export const AdminCard = (props: AdminCardProps) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
  }, [props.editSuccess]);
  return (
    <>
      <EditOutlined
        style={{ color: "#109DED" }}
        key="edit"
        onClick={showModal}
      ></EditOutlined>
      {visible === true && (
        <Modal
          title="Edit admin"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <EditAdmin
            editAdmin={(admin: EditAdminPayload) => props.edit(admin)}
            adminData={props.adminData}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
