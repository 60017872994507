import { useEffect, useState } from "react";

import { Form, Input, Button, Spin, Upload, Space, message } from "antd";
import {
  Advertisement,
  EditAdvertisementPayload,
} from "../../../types/advertisement";

type EditAdvertisementProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  editAdvertisement: (advertisement: EditAdvertisementPayload) => void;
  isCanceled: () => void;
  id: string;
  advertisement?: Advertisement;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const EditAdvertisement = (props: EditAdvertisementProps): JSX.Element => {
  const token = localStorage.getItem("token");
  const [initialValues, setInitialValues] = useState<any>();
  const [previewsImage, setPreviewImage] = useState<{
    url?: string;
    uid?: string;
  }>();

  const onFinish = async (values: any) => {
    const image =
      values?.image[0]?.response?.length > 0
        ? values?.image[0]?.response[0]?.Location
        : values?.image[0]?.url;
    props.editAdvertisement({
      order: parseInt(values.order),
      image,
      _id: props.id,
    });
  };

  useEffect(() => {
    if (props.advertisement) {
      setInitialValues({
        image: [
          {
            name: props.advertisement.image,
            url: props.advertisement.image,
            uid: props.advertisement.image,
          },
        ],
        order: props.advertisement.order,
      });

      setPreviewImage({
        url: props.advertisement.image,
      });
    }
  }, [props.advertisement]);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Order: "
          name="order"
          rules={[{ required: true, message: "Please Enter" }]}
        >
          <Input
            className="w-60 rounded-full"
            defaultValue={props.advertisement?.order}
          />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "image is required" }]}
          name="image"
          label="Image:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={"https://api.joomlachi.potan.co/upload"}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                message.success(`file uploaded successfully`);
                setPreviewImage({
                  url: info.file.response.url,
                });
              }
            }}
          >
            <Button className="w-60 rounded-full hover:text-primary hover:border-primary">
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default EditAdvertisement;
