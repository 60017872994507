import { useState } from "react";

import { Form, Input, Button, Spin, Upload, message, Select } from "antd";

import { AddDriverPayload } from "../../../types/driver";
import { Option } from "antd/lib/mentions";
import { useGetSellersQuery } from "../../../app/services/user";
import { User } from "../../../types/user";

type AddDriverProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addDriver: (driver: AddDriverPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

export const AddDriver = (props: AddDriverProps): JSX.Element => {
  const token = localStorage.getItem("token");

  const normFile = (e: any) => {
    return e && e.fileList;
  };
  const { data: sellersData } = useGetSellersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const onFinish = async (values: any) => {
    let profilePicture = values?.profilePicture[0].response[0].Location;
    let driver = {
      fullName: values.fullName,
      phoneNumber: values.phoneNumber,
      profilePicture: profilePicture,
      seller: values.seller,
    };
    props.addDriver(driver);
  };
  const onChange = (value: string) => {
    console.log(value);
  };
  const onSearch = (value: string) => {
    console.log(value);
  };

  return (
    <>
      <Form {...layout} name="basic" onFinish={onFinish}>
        <Form.Item
          label="Full Name"
          name="fullName"
          rules={[{ required: true, message: "Please input your full name!" }]}
        >
          <Input className="rounded-full" />
        </Form.Item>

        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <Input className="rounded-full" />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Picture is required" }]}
          name="profilePicture"
          label="Picture:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={"https://api.joomlachi.potan.co/upload"}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button className="w-60 rounded-full hover:text-primary hover:border-primary">
              Click to upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Seller"
          name="seller"
          rules={[{ required: true, message: "Please input your seller!" }]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            onChange={onChange}
            onSearch={onSearch}
            placeholder="Select a seller"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {sellersData?.result?.map((seller: User) => (
              <Option value={seller._id}>{seller.fullName}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item className=" flex flex-col w-full">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};
