import qs from "qs";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { AddOrderPayload, EditOrderPayload, Order } from "../../types/order";
import { baseApi } from "./baseApi";
import { PaginatedResponse } from "../../types/PaginatedResponse";

export const orderApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrders: build.query<PaginatedResponse<Order>, PaginatedPayload<Order>>({
      query: (payload) => ({
        url: `orders?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    getOrder: build.query<Order, string>({
      query: (id) => ({
        url: `orders/${id}`,
        method: "GET",
      }),
    }),

    addOrder: build.mutation<Order, AddOrderPayload>({
      query: (payload) => ({
        url: `orders/admin`,
        method: "POST",
        body: payload,
      }),
    }),

    editOrder: build.mutation<Order, EditOrderPayload>({
      query: (payload) => ({
        url: `orders/admin/${payload._id}`,
        method: "PATCH",
        body: {
          buyer: payload.buyer,
          // driver: payload.driver,
          products: payload.products,
          discountId: payload.discountId,
          // driverInstruction: payload.driverInstruction,
          // sellerInstruction: payload.sellerInstruction,
          address: payload.address,
        },
      }),
    }),

    deleteOrder: build.mutation<Order, string>({
      query: (id) => ({
        url: `orders/${id}`,
        method: "DELETE",
      }),
    }),

    deativateOrder: build.mutation<Order, string>({
      query: (id) => ({
        url: `orders/deactivate/${id}`,
        method: "PATCH",
      }),
    }),

    approveOrder: build.mutation<Order, string>({
      query: (id) => ({
        url: `orders/approve/${id}`,
        method: "PATCH",
      }),
    }),

    assignOrder: build.mutation<Order, string>({
      query: (id) => ({
        url: `orders/assign/${id}`,
        method: "PATCH",
      }),
    }),

    deliverOrder: build.mutation<Order, string>({
      query: (id) => ({
        url: `orders/deliver/${id}`,
        method: "PATCH",
      }),
    }),

    cancelOrder: build.mutation<
      Order,
      { id: string; cancellationReason: string }
    >({
      query: (data) => ({
        url: `orders/cancel/${data.id}`,
        method: "PATCH",
        body: {
          cancellationReason: data.cancellationReason,
        },
      }),
    }),

    completeOrder: build.mutation<Order, string>({
      query: (id) => ({
        url: `orders/complete/${id}`,
        method: "PATCH",
      }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useDeleteOrderMutation,
  useDeativateOrderMutation,
  useApproveOrderMutation,
  useAssignOrderMutation,
  useDeliverOrderMutation,
  useCancelOrderMutation,
  useCompleteOrderMutation,
  useEditOrderMutation,
  useAddOrderMutation,
} = orderApi;
