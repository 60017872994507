import { baseApi } from "./baseApi";
import {
  Product,
  AddProductPayload,
  EditProductPayload,
} from "../../types/product";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import qs from "query-string";

export const productApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query<
      PaginatedResponse<Product>,
      PaginatedPayload<Product>
    >({
      query: (payload) => ({
        url: `products?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    getProduct: build.query<Product, string>({
      query: (id) => ({
        url: `products/${id}`,
        method: "GET",
      }),
    }),

    addProduct: build.mutation<Product, AddProductPayload>({
      query: (payload) => ({
        url: `products`,
        method: "POST",
        body: payload,
      }),
    }),

    editProduct: build.mutation<Product, EditProductPayload>({
      query: (payload) => ({
        url: `products/${payload._id}`,
        method: "PATCH",
        body: {
          category: payload.category,
          unit: payload.unit,
          unitNumber: payload.unitNumber,
          stock: payload.stock,
          price: payload.price,
          currency: payload.currency,
          description: payload.description,
          name: payload.name,
          seller: payload.seller,
          images: payload.images,
        },
      }),
    }),

    deleteProduct: build.mutation<Product, string>({
      query: (id) => ({
        url: `products/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useAddProductMutation,
  useEditProductMutation,
  useDeleteProductMutation,
} = productApi;
