import { PaginatedPayload } from "../../types/PaginatedPayload";
import { AddDiscountPayload, EditDiscountPayload, Discount } from "../../types/discount";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { baseApi } from "./baseApi";
import qs from "query-string";

export const discountApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getDiscounts: build.query<PaginatedResponse<Discount>, PaginatedPayload<Discount>>({
            query: (payload) => ({
                url: `discounts?${qs.stringify(payload)}`,
                method: "GET",
            }),
        }),

    
        getDiscount: build.query<Discount, string>({
            query: (id) => ({
                url: `discounts/${id}`,
                method: "GET",
            }),
        }),

        addDiscount: build.mutation<Discount, AddDiscountPayload>({
            query: (payload) => ({
                url: `discounts`,
                method: "POST",
                body: payload,
            }),
        }),

        editDiscount: build.mutation<Discount, EditDiscountPayload>({
            query: (payload) => ({
                url: `discounts/${payload._id}`,
                method: "PATCH",
                body: {
                    code: payload.code,
                    amount: payload.amount,
                    currency: payload.currency,
                    isPercentage: payload.isPercentage,
                    expiryDate: payload.expiryDate,
                    numberOfDiscounts: payload.numberOfDiscounts,
                    products: payload.products,
                    categories: payload.categories,
                    sellers: payload.sellers,
                    applyToAll: payload.applyToAll,
                    users: payload.users,
                    public: payload.public,
                },
            }),
        }),

        
        deleteDiscount: build.mutation<Discount, string>({
            query: (id) => ({
                url: `discounts/${id}`,
                method: "DELETE",
            }),

        }),
    }),

});

export const {
    useGetDiscountsQuery,
    useGetDiscountQuery,
    useAddDiscountMutation,
    useEditDiscountMutation,
    useDeleteDiscountMutation,
} = discountApi;



