import { Form, Input, Button, Spin, Upload, message, Space } from "antd";

import { AddCategoryPayload } from "../../../types/category";

type AddUserProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addCategory: (user: AddCategoryPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const AddCategory = (props: AddUserProps): JSX.Element => {
  const token = localStorage.getItem("token");

  const onFinish = async (values: any) => {
    let profilePicture = values?.icon[0].response[0].Location;

    props.addCategory({
      order: parseInt(values.order),
      name: values.name,
      icon: profilePicture,
    });
  };

  const [form] = Form.useForm();

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="name"
          label="Category Name"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="name">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          label="Order: "
          name="order"
          rules={[{ message: "order is required", required: true }]}
        >
          <Input type="number" className="w-60 rounded-full" />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Icon is required" }]}
          name="icon"
          label="Icon:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
          // initialValue={userData?.profilePicture}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={"https://api.joomlachi.potan.co/upload"}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button className="w-60 rounded-full hover:text-primary hover:border-primary">
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default AddCategory;
