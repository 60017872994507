import { Form, Input, Button, Upload, message, Select } from "antd";
import { EditDriverPayload, Driver } from "../../../types/driver";
import { Option } from "antd/lib/mentions";
import { useGetSellersQuery } from "../../../app/services/user";
import { User } from "../../../types/user";
import { useEffect, useState } from "react";

type EditDriverProps = {
  driver: Driver;
  edit: (driver: EditDriverPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

export const EditDriver = (props: EditDriverProps) => {
  const token = localStorage.getItem("token");
  const [form] = Form.useForm();
  const [previewsImage, setPreviewImage] = useState<{
    url?: string;
    uid?: string;
  }>();

  const { data: sellersData } = useGetSellersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const onFinish = async (values: any) => {
    console.log("values", values);
    if (values?.profilePicture[0]?.response) {
      let profilePicture = values?.profilePicture[0]?.response[0]?.Location;
      let driver = {
        _id: props.driver._id,
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        profilePicture: profilePicture,
        seller: values.seller,
      };
      props.edit(driver);
    } else {
      let driver = {
        _id: props.driver?._id,
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        profilePicture: previewsImage?.url,
        seller: values.seller,
      };
      props.edit(driver);
    }
  };
  const onChange = (value: string) => {
    console.log(value);
  };
  const onSearch = (value: string) => {
    console.log(value);
  };

  useEffect(() => {
    props.driver &&
      form.setFieldsValue({
        fullName: props.driver.fullName,
        phoneNumber: props.driver.phoneNumber,
        seller: {
          label: props.driver.seller?.fullName,
          value: props.driver.seller?._id,
        },
        profilePicture: [
          {
            uid: props.driver._id,
            name: props.driver.fullName,
            url: props.driver.profilePicture,
          },
        ],
      });
    setPreviewImage({
      url: props.driver.profilePicture,
    });
    // console.log(props.driver);
  }, [props.driver]);

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form {...layout} name="basic" onFinish={onFinish} form={form}>
        <Form.Item
          label="Full Name"
          name="fullName"
          rules={[{ required: true, message: "Please input your full name!" }]}
        >
          <Input className="rounded-full" />
        </Form.Item>

        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <Input className="rounded-full" />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Picture is required" }]}
          name="profilePicture"
          label="Picture:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={"https://api.joomlachi.potan.co/upload"}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button className="w-60 rounded-full hover:text-primary hover:border-primary">
              Click to upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Seller"
          name="seller"
          rules={[{ required: true, message: "Please input your seller!" }]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            onChange={onChange}
            onSearch={onSearch}
            placeholder="Select a seller"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {sellersData?.result?.map((seller: User) => (
              <Option value={seller?._id}>{seller?.fullName}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item className=" flex flex-col w-full">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
