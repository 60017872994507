import React, { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { UserCard } from "./userCard";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";
import {
  useAddUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useEditUserMutation,
} from "../../../app/services/user";

import { User, AddUserPayload } from "../../../types/user";
import { Avatar, message, Modal, Space } from "antd";
import AddUser from "./addUser";
import { parseError } from "../../../utils/utils";
import Table, { ColumnsType } from "antd/lib/table";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { City } from "../../../types/city";

export const Users = () => {
  interface UserCellType {
    index: number;
    _id: string;
    fullName: string;
    email: string;
    password: string;
    roles: string[];
    isActive: boolean;
    isSeller: boolean;
    isRegistered: boolean;
    profilePicture: string;
    isVerified: boolean;
    city: City;
    phoneNumber: string;
  }

  const columns: ColumnsType<UserCellType> = [
    {
      title: "Action",
      key: "action",

      fixed: "left",
      width: 100,
      render: (record) => (
        <>
          <Space className="mr-3">
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={() => {
                showDeleteConfirm(record._id);
              }}
            />
          </Space>
          <Space className="ml-3">
            <UserCard
              editSuccess={isEditUserSuccess}
              user={record}
              edit={editUser}
            ></UserCard>
          </Space>
        </>
      ),
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: 100,
    },

    {
      title: "profilePicture",
      dataIndex: "profilePicture",
      key: "profilePicture",
      render: (profilePicture: string) => (
        <Avatar src={profilePicture} size={30} />
      ),
    },
    {
      title: "fullName",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "roles",
      dataIndex: "roles",
      key: "roles",
      render: (roles: string[]) => roles.join(", "),
    },

    // {
    //   title: "isActive",
    //   dataIndex: "isActive",
    //   key: "isActive",
    // },
    // {
    //   title: "isSeller",
    //   dataIndex: "isSeller",
    //   key: "isSeller",
    // },
    // {
    //   title: "isRegistered",
    //   dataIndex: "isRegistered",
    //   key: "isRegistered",
    // },

    {
      title: "city",
      dataIndex: "city",
      key: "city",
      render: (value) => {
        return <div>{value?.name[0]?.value}</div>;
      },
    },
    {
      title: "phoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },

    {
      title: "Is verified",
      dataIndex: "isVerified",
      key: "isVerified",
      render: (value) => {
        return <div>{value.toString()}</div>;
      },
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const { confirm } = Modal;
  const [visible, setVisible] = useState(false);
  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this User?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        deleteUser(id);
      },
      onCancel() {},
    });
  }
  const {
    isFetching: isFetchingUser,
    data: usersData,
    refetch,
  } = useGetUsersQuery({
    page: currentPage,
    limit: 10,
  });

  const [
    editUser,
    {
      isSuccess: isEditUserSuccess,
      isError: isEditUserError,
      error: editUserError,
      reset: resetEditUser,
    },
  ] = useEditUserMutation();

  const [
    addUser,
    {
      isLoading: isAddUserLoading,
      isSuccess: isAddUserSuccess,
      isError: isAddUserError,
      error: addUserError,
      reset: resetAddUser,
    },
  ] = useAddUserMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [
    deleteUser,
    {
      isSuccess: isDeleteUserSuccess,
      isError: isDeleteUserError,
      error: deleteUserError,
      reset: resetDeleteUser,
    },
  ] = useDeleteUserMutation();

  useEffect(() => {
    if (isEditUserSuccess) {
      message.success("User updated successfully");
      resetEditUser();
    }

    if (isEditUserError) {
      message.error(parseError(editUserError));
      resetEditUser();
    }
    if (isAddUserSuccess) {
      message.success("User added successfully");
    }
    resetAddUser();
    if (isAddUserError) {
      message.error(parseError(addUserError));
    }
    resetAddUser();
    if (isDeleteUserSuccess) {
      message.success("User deleted successfully");
      resetDeleteUser();
    }
    if (isDeleteUserError) {
      message.error(parseError(deleteUserError));
      resetDeleteUser();
    }
  }, [
    isDeleteUserSuccess,
    isEditUserSuccess,
    isAddUserSuccess,
    isAddUserError,
    isDeleteUserError,
    isEditUserError,
  ]);

  useEffect(() => {
    refetch();
    handleOk();
  }, [
    isEditUserSuccess,
    isAddUserSuccess,
    isDeleteUserSuccess,
    isEditUserError,
  ]);
  const data: UserCellType[] = [];

  return (
    <>
      <div className=" p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-h-4 fill-white" />
        </button>
      </div>
      {usersData?.result !== undefined &&
        usersData?.result.map((user: User) => {
          data.push({
            index: data.length + 1,
            fullName: user.fullName,
            email: user.email,
            roles: user.roles,
            _id: user._id,
            city: user.city,
            isVerified: user.isVerified,
            isActive: user.isActive,
            phoneNumber: user.phoneNumber,
            isRegistered: user.isRegistered,
            isSeller: user.isSeller,
            password: user.password,
            profilePicture: user.profilePicture,
          });
        })}

      <Table
        bordered
        columns={columns}
        dataSource={data}
        loading={isFetchingUser}
        scroll={{ x: 2000, y: 450 }}
        pagination={false}
      />

      <Pagination
        itemsPerPage={10}
        totalItems={usersData?.count ? usersData.count : 20}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new user"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddUser
            isLoading={isAddUserLoading}
            addUser={(user: AddUserPayload) => addUser(user)}
            isSuccess={isAddUserSuccess}
            error={isAddUserError}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
