import { Discount, EditDiscountPayload } from "../../../types/discount";
import { useEffect, useState } from "react";
import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useGetCategoriesQuery } from "../../../app/services/category";
import {
  useGetSellersQuery,
  useGetUsersQuery,
} from "../../../app/services/user";
import { Category } from "../../../types/category";
import { User } from "../../../types/user";
import moment from "moment";

type EditDiscountProps = {
  editDiscount: (category: EditDiscountPayload) => void;
  isCanceled: () => void;
  discount: Discount;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const EditDiscount = (props: EditDiscountProps): JSX.Element => {
  const onChange = (value: string) => {
    console.log(value);
  };
  const onSearch = (value: string) => {
    console.log(value);
  };
  // const [discountExpireyDate, setDiscountExpireyDate] = useState("");
  const [form] = Form.useForm();
  const [discountDate, setDiscountDate] = useState("");

  const { data: categoriesData } = useGetCategoriesQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const { data: sellersData } = useGetSellersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });
  const { data: usersData } = useGetUsersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const onFinish = async (values: any) => {
    props.editDiscount({
      _id: props.discount._id,
      code: values.code,
      amount: parseInt(values.amount),
      currency: values.currency,
      isPercentage: values.isPercentage,
      expiryDate: values.expiryDate,
      numberOfDiscounts: parseInt(values.numberOfDiscounts),

      products: values.products?.map((p: any) => {
        if (p.value) {
          return p._id;
        } else {
          return p;
        }
      }),
      categories: values.categories?.map((category: any) => {
        if (category.value) {
          return category.value;
        } else {
          return category;
        }
      }),
      sellers: values.sellers?.map((seller: any) => {
        if (seller.value) {
          return seller.value;
        } else {
          return seller;
        }
      }),
      applyToAll: values.applyToAll,
      users: values.users?.map((user: any) => {
        if (user.value) {
          return user.value;
        } else {
          return user;
        }
      }),
      public: values.public,
    });
  };

  useEffect(() => {
    if (props.discount) {
      console.log(props.discount);
      form.setFieldsValue({
        code: props.discount.code,
        amount: props.discount.amount,
        currency: props.discount.currency,
        isPercentage: props.discount.isPercentage,
        expiryDate: moment(props.discount.expiryDate),
        numberOfDiscounts: props.discount.numberOfDiscounts,
        products: props.discount.products?.map((product) => {
          return { value: product._id, label: product.name };
        }),
        categories: props.discount.categories?.map((category) => {
          return { label: category.name[0].value, value: category._id };
        }),
        sellers: props.discount.sellers?.map((seller) => {
          return { label: seller.fullName, value: seller._id };
        }),

        applyToAll: props.discount.applyToAll,
        users: props.discount.users?.map((user) => {
          return { label: user.fullName, value: user._id };
        }),
        public: props.discount.public,
      });
    }
  }, [props.discount]);

  const dateOnChange = (date: any, dateString: any) => {
    setDiscountDate(dateString);
    console.log(dateString);
    console.log("date:" + discountDate);
  };

  // function formatDate() {
  //   if (props.discount?.expiryDate) {
  //     const date = new Date(props.discount?.expiryDate);
  //     const month = date.getMonth() + 1;
  //     const day = date.getDate();
  //     const year = date.getFullYear();
  //     const formattedDate = `${day}-${month}-${year}`;
  //     setDiscountExpireyDate(formattedDate);
  //   }
  // }

  return (
    <>
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
        name="basic"
        initialValues={{ remember: true }}
      >
        <Form.Item label="Code" name="code" rules={[{ required: true }]}>
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
          <Input className="w-60 rounded-full" />
        </Form.Item>

        <Form.Item
          label="is Percentage"
          name="isPercentage"
          valuePropName="checked"
        >
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item
          label="Apply to all"
          name="applyToAll"
          valuePropName="checked"
        >
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item label="public" name="public" valuePropName="checked">
          <Checkbox></Checkbox>
        </Form.Item>

        <Form.Item
          label="End Date"
          name="expiryDate"
          rules={[{ required: true }]}
        >
          <DatePicker
            onChange={dateOnChange}
            className="rounded-full"
            format="DD/MM/YYYY"
          />
        </Form.Item>

        <Form.Item
          label="Number of discounts"
          name="numberOfDiscounts"
          rules={[{ required: true }]}
        >
          <Input type="number" className="w-60 rounded-full" />
        </Form.Item>

        <Form.Item label="Category" name="categories">
          <Select
            showSearch
            mode="multiple"
            placeholder="Select a categories"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {categoriesData?.result?.map((category: Category) => (
              <Option className="rounded-full" value={category._id}>
                {category.name[0].value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Sellers" name="sellers">
          <Select
            mode="multiple"
            showSearch
            placeholder="Select sellers"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {sellersData?.result?.map((seller: User) => (
              <Option className="rounded-full" value={seller._id}>
                {seller.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Users" name="users">
          <Select
            mode="multiple"
            showSearch
            placeholder="Select users"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {usersData?.result?.map((user: User) => (
              <Option className="rounded-full" value={user._id}>
                {user.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Currency:"
          name="currency"
          rules={[{ required: true, message: "Please input your currency!" }]}
        >
          <Select
            placeholder="Select a currency"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option className="rounded-full" value="usd">
              USD
            </Option>
            <Option className="rounded-full" value="iqd">
              IQD
            </Option>
          </Select>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditDiscount;
