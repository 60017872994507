import { Form, Input, Button, Spin, Upload, message, Space } from "antd";

import { AddAdvertisementPayload } from "../../../types/advertisement";

type AddUserProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addAdvertisement: (user: AddAdvertisementPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const AddAdvertisement = (props: AddUserProps): JSX.Element => {
  const token = localStorage.getItem("token");

  const onFinish = async (values: any) => {
    const image = values?.image[0].response[0].Location;
    props.addAdvertisement({
      order: parseInt(values.order),
      image,
    });
  };

  const [form] = Form.useForm();

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Order: "
          name="order"
          rules={[{ message: "order is required", required: true }]}
        >
          <Input type="number" className="w-60 rounded-full" />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Image is required" }]}
          name="image"
          label="Image:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
          // initialValue={userData?.profilePicture}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={"https://api.joomlachi.potan.co/upload"}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button className="w-60 rounded-full hover:text-primary hover:border-primary">
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default AddAdvertisement;
