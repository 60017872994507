import { baseApi } from "./baseApi";
import { City, AddCityPayload, EditCityPayload } from "../../types/city";
import qs from "query-string";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { PaginatedResponse } from "../../types/PaginatedResponse";

export const cityApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCities: build.query<PaginatedResponse<City>, PaginatedPayload<City>>({
      query: (payload) => ({
        url: `cities?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    getCity: build.query<City, string>({
      query: (id) => ({
        url: `cities/${id}`,
        method: "GET",
      }),
    }),

    addCity: build.mutation<City, AddCityPayload>({
      query: (payload) => ({
        url: `cities`,
        method: "POST",
        body: payload,
      }),
    }),

    editCity: build.mutation<City, EditCityPayload>({
      query: (payload) => ({
        url: `cities/${payload._id}`,
        method: "PATCH",
        body: {
          name: payload.name,
        },
      }),
    }),

    deleteCity: build.mutation<City, string>({
      query: (id) => ({
        url: `cities/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useGetCityQuery,
  useAddCityMutation,
  useEditCityMutation,
  useDeleteCityMutation,
} = cityApi;
