import { useState } from "react";

import {
  Form,
  Input,
  Button,
  Spin,
  Upload,
  message,
  Select,
  RadioChangeEvent,
  Radio,
} from "antd";

import { AddUserPayload } from "../../../types/user";
import { useGetCitiesQuery } from "../../../app/services/city";
import { City } from "../../../types/city";
import { Option } from "antd/lib/mentions";

type AddUserProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: boolean;
  addUser: (user: AddUserPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const AddUser = (props: AddUserProps): JSX.Element => {
  const options = [
    { label: "Is Buyer", value: "isBuyer" },
    { label: "Is Seller", value: "isSeller" },
    // { label: "Seller and Buyer", value: "isSellerAndBuyer" },
  ];

  const token = localStorage.getItem("token");

  const [userType, setUserType] = useState("isBuyer");

  const { data: citiesData } = useGetCitiesQuery({
    page: 1,
    limit: 10000000,
  });

  const onFinish = async (values: any) => {
    let profilePicture = values?.profilePicture[0].response[0].Location;
    let isSeller = false;
    if (userType === "isBuyer") {
      // values.isBuyer = true;
      isSeller = false;
      console.log("user is buyer");
    }
    if (userType === "isSeller") {
      isSeller = true;
      console.log("user is seller");
      // values.isBuyer = false;
    }
    // if (values.userType === "isSellerAndBuyer") {
    //   values.isSeller = true;
    //   // values.isBuyer = true;
    // }

    console.log(values);
    props.addUser({
      email: values?.email,
      fullName: values?.fullName,
      profilePicture: profilePicture,
      city: values?.city,
      isVerified: values.isVerified,
      phoneNumber: values?.phoneNumber,
      roles: isSeller ? ["SELLER"] : ["BUYER"],
      isRegistered: true,
      isSeller: isSeller,
      // isBuyer: values?.isBuyer,
      //   password: values?.password,
    });
  };

  const [form] = Form.useForm();

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  const onChange = (value: string) => {
    console.log(value);
  };

  const onSearch = (value: string) => {
    console.log(value);
  };
  const onChange1 = ({ target: { value } }: RadioChangeEvent) => {
    setUserType(value);
  };
  const baseUrl = process.env.REACT_APP_API_URL;

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Full Name: "
          name="fullName"
          rules={[{ required: true, message: "fullName is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Email: "
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input type="email" className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Phone Number: "
          name="phoneNumber"
          rules={[{ required: true, message: "phoneNumber is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: "Location is required" }]}
        >
          <Select
            showSearch
            placeholder="Select a city"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {citiesData?.result?.map((city: City) => (
              <Option className="rounded-full" value={city._id}>
                {city.name[0].value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Choose Role"
          name="userRole"
          initialValue={userType}
          rules={[{ required: true, message: "roles is required" }]}
        >
          <Radio.Group
            defaultValue={userType}
            options={options}
            style={{ color: "red" }}
            onChange={onChange1}
            value={userType}
          />
        </Form.Item>

        <Form.Item
          label="Is user verified"
          name="isVerified"
          valuePropName="checked"
        >
          <Radio>Is verified</Radio>
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "profilePicture is required" }]}
          name="profilePicture"
          label="profilePicture:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
          // initialValue={userData?.profilePicture}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={`${baseUrl}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                message.success(`file uploaded successfully`);
              } else if (info.file.status === "error") {
                message.error(
                  `File upload failed:  ${
                    info.file.response || "File too large"
                  }`
                );
              }
            }}
          >
            <Button className="w-60 rounded-full hover:text-primary hover:border-primary">
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col ">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default AddUser;
