import { useEffect, useState } from "react";

import { Form, Input, Button, Spin, Upload, Space, message } from "antd";
import { Category, EditCategoryPayload } from "../../../types/category";

type EditCategoryProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  editCategory: (category: EditCategoryPayload) => void;
  isCanceled: () => void;
  id: string;
  category?: Category;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const EditCategory = (props: EditCategoryProps): JSX.Element => {
  const token = localStorage.getItem("token");
  const [initialValues, setInitialValues] = useState<any>();
  const [previewsImage, setPreviewImage] = useState<{
    url?: string;
    uid?: string;
  }>();

  const onFinish = async (values: any) => {
    console.log("values", values.profilePicture);
    let profilePicture =
      values?.profilePicture[0]?.response?.length > 0
        ? values?.profilePicture[0]?.response[0]?.Location
        : values?.profilePicture[0]?.url;
    console.log("values1", profilePicture);
    props.editCategory({
      name: values.name,
      order: parseInt(values.order),
      icon: profilePicture,
      _id: props.id,
    });
  };

  useEffect(() => {
    if (props.category) {
      setInitialValues({
        name: props.category.name,
        order: props.category.order,
        profilePicture: [
          {
            url: props.category.icon,
            uid: props.category._id,
            name: props.category.icon,
          },
        ],
      });

      setPreviewImage({
        url: props.category.icon,
      });
    }
  }, [props.category]);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
    console.log(initialValues);
  }, [form, initialValues]);

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="name"
          label="Category Name"
          initialValue={[
            {
              key: "en",
              val: "",
            },
            {
              key: "ar",
              val: "",
            },
            {
              key: "ku",
              val: "",
            },
          ]}
        >
          <Form.List name="name">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,

                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, required:true,message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          label="Order: "
          name="order"
          rules={[{ required: true, message: "Please Enter" }]}
        >
          <Input
            className="w-60 rounded-full"
            defaultValue={props.category?.order}
          />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Picture is required" }]}
          name="profilePicture"
          label="Picture:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={"https://api.joomlachi.potan.co/upload"}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                message.success(`file uploaded successfully`);
                console.log("info", info);
                setPreviewImage({
                  url: info.file.response.url,
                });
                console.log(previewsImage);
              }
            }}
          >
            <Button className="w-60 rounded-full hover:text-primary hover:border-primary">
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default EditCategory;
