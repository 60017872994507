import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

// Example items, to simulate fetching from another resources.

type TProps = {
  itemsPerPage: number;
  getCurrentPage: (page: number) => void;
  totalItems: number;
};

export const Pagination = ({
  itemsPerPage,
  getCurrentPage,
  totalItems,
}: TProps) => {
  // We start with an empty list of items.
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.

  useEffect(() => {
    // Fetch items from another resources.
    setPageCount(Math.ceil(totalItems / itemsPerPage));
  }, [itemsPerPage, totalItems]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    console.log(event);
    const selectedPage = event.selected + 1;
    getCurrentPage(selectedPage);
  };

  return (
    <div className="w-full flex justify-center mt-4 ">
      <ReactPaginate
        breakLabel="..."
        activeClassName="bg-primary text-white flex flex-col justify-center items-center px-3 py-1 rounded-full "
        className="flex justify-center items-center w-6/12 text-sm gap-5 !bg-white !text-primary p-3 rounded-full shadow-sm"
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="red "
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="red"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        }
        // renderOnZeroPageCount={null}
      />
    </div>
  );
};
