import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
// import { DriverCard } from "./driverCard";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";
import {
  useGetDriversQuery,
  useAddDriverMutation,
  useDeleteDriverMutation,
  useEditDriverMutation,
} from "../../../app/services/driver";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { parseError } from "../../../utils/utils";

import { Avatar, message, Modal, Space } from "antd";

import { AddDriverPayload, Driver } from "../../../types/driver";
import { DriverCard } from "./driverCard";
import { AddDriver } from "./addDriver";
import Table, { ColumnsType } from "antd/lib/table";
import { User } from "../../../types/user";

export const Drivers = () => {
  interface DriverCellType {
    index: number;
    _id: string;
    fullName: string;
    phoneNumber: string;
    profilePicture: string;
    seller: User;
  }

  const columns: ColumnsType<DriverCellType> = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Space className="mr-3">
            <DeleteOutlined
              key="delete"
              onClick={() => showDeleteConfirm(record._id)}
              style={{ color: "red" }}
            />
          </Space>
          <Space className="ml-3">
            <DriverCard
              edit={editDriver}
              driver={record}
              editSuccess={isEditDriverSuccess}
            ></DriverCard>
          </Space>
        </>
      ),
      width: 100,
      fixed: "left",
    },
    {
      title: "index",
      dataIndex: "index",
      key: "index",
      width: 150,
    },
    {
      title: "profilePicture",
      dataIndex: "profilePicture",
      key: "profilePicture",
      render: (profilePicture: string) => (
        <Avatar src={profilePicture} size={30}></Avatar>
      ),
      width: 150,
    },
    {
      title: "fullName",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "seller",
      dataIndex: "seller",
      key: "seller",
      ellipsis: true,
      render: (seller: User) => seller?.fullName,
    },
    {
      title: "phoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
  ];

  const data: DriverCellType[] = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const { confirm } = Modal;

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this driver?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        deleteDriver(id);
      },
      onCancel() {},
    });
  }
  const {
    isFetching: isFetchingDrivers,
    data: driversData,
    refetch,
  } = useGetDriversQuery({
    page: currentPage,
    limit: 10,
  });

  const [
    editDriver,
    {
      isSuccess: isEditDriverSuccess,
      isError: isEditDriverError,
      error: editDriverError,
      reset: resetEditDriver,
    },
  ] = useEditDriverMutation();

  const [
    addDriver,
    {
      isLoading: isAddDriverLoading,
      isSuccess: isAddDriverSuccess,
      isError: isAddDriverError,
      error: addDriverError,
      reset: resetAddDriver,
    },
  ] = useAddDriverMutation();

  const [
    deleteDriver,
    {
      isSuccess: isDeleteDriverSuccess,
      isError: isDeleteDriverError,
      error: deleteDriverError,
      reset: resetDeleteDriver,
    },
  ] = useDeleteDriverMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (isAddDriverSuccess) {
      message.success("Driver added successfully");
      refetch();
      resetAddDriver();
    }
    if (isAddDriverError) {
      message.error(parseError(addDriverError));
      resetAddDriver();
    }

    if (isEditDriverSuccess) {
      message.success("Driver edited successfully");
      resetEditDriver();
      refetch();
    }
    if (isEditDriverError) {
      message.error(parseError(editDriverError));
      resetEditDriver();
    }

    if (isDeleteDriverSuccess) {
      message.success("Driver deleted successfully");
      resetDeleteDriver();
      refetch();
    }
    if (isDeleteDriverError) {
      message.error(parseError(deleteDriverError));
      resetDeleteDriver();
    }
  }, [
    isAddDriverSuccess,
    isAddDriverError,
    isEditDriverSuccess,
    isEditDriverError,
    isDeleteDriverSuccess,
    driversData,
    isDeleteDriverError,
  ]);

  return (
    <>
      <div className="  p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-h-4 fill-white" />
        </button>
      </div>
      {driversData?.result !== undefined &&
        driversData?.result.map((driver: Driver) => {
          data.push({
            index: data.length + 1,
            _id: driver._id,
            fullName: driver.fullName,
            phoneNumber: driver.phoneNumber,
            profilePicture: driver.profilePicture,
            seller: driver.seller,
          });
        })}
      <Table
        scroll={{ x: 2000, y: 450 }}
        bordered
        columns={columns}
        pagination={false}
        loading={isFetchingDrivers}
        dataSource={data}
      />

      <Pagination
        itemsPerPage={10}
        totalItems={driversData?.count ? driversData.count : 20}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new Driver"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddDriver
            isLoading={isAddDriverLoading}
            addDriver={(driver: AddDriverPayload) => addDriver(driver)}
            isSuccess={isAddDriverSuccess}
            error={isAddDriverError}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
