import { baseApi } from "./baseApi";
import {
  Admin,
  AddAdminPayload,
  ResetPassword,
  EditAdminPayload,
} from "../../types/admin";
import qs from "query-string";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { PaginatedResponse } from "../../types/PaginatedResponse";

export const adminApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAdmins: build.query<PaginatedResponse<Admin>, PaginatedPayload<Admin>>({
      query: (payload) => ({
        url: `admins?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    getAdmin: build.query<Admin, string>({
      query: (id) => ({
        url: `admins/${id}`,
        method: "GET",
      }),
    }),

    addAdmin: build.mutation<Admin, AddAdminPayload>({
      query: (payload) => ({
        url: `admins`,
        method: "POST",
        body: payload,
      }),
    }),

    editAdmin: build.mutation<Admin, EditAdminPayload>({
      query: (payload) => ({
        url: `admins/${payload._id}`,
        method: "PATCH",
        body: {
          username: payload.username,
          email: payload.email,
          password: payload.password,
          roles: payload.roles,
          isAdmin: payload.isAdmin,
          isActive: payload.isActive,
        },
      }),
    }),

    deleteAdmin: build.mutation<Admin, string>({
      query: (id) => ({
        url: `admins/${id}`,
        method: "DELETE",
      }),
    }),

    resetPassword: build.mutation<Admin, ResetPassword>({
      query: (payload) => ({
        url: `admins/reset-password`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useGetAdminQuery,
  useAddAdminMutation,
  useEditAdminMutation,
  useDeleteAdminMutation,
  useResetPasswordMutation,
} = adminApi;
