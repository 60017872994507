import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../utils/utils";
import logo from "../assets/images/logo-placeholder.png";

type TProps = {
  name: string;
  styles: string;
  setIsSelected?: (name: string) => void;
  svg: JSX.Element;
  isCollapsed: boolean;
  onClick?: () => void;
};

const NavItem = ({ name, styles, setIsSelected, svg, isCollapsed }: TProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={` font-normal text-lg flex cursor-pointer place-items-center gap-1 w-full px-3 py-2 rounded-full ${styles}`}
      onClick={() => {
        setIsSelected !== undefined && setIsSelected(name);
        navigate(`/${name === "dashboard" ? name : "dashboard/" + name}`);
      }}
    >
      <div className="flex flex-row items-center pt-1  w-full  place-items-center gap-x-5 ">
        <div className="h-full  flex place-items-center">{svg}</div>
        <div className=" pb-1 text-base ">
          {!isCollapsed && capitalizeFirstLetter(name)}
        </div>
      </div>
      {/* <div className={`${isCollapsed ? "opacity-0" : "opacity-100"}`}>
        {capitalizeFirstLetter(name)}
      </div> */}
      {/* {!isCollapsed && <div></div>} */}
    </div>
  );
};

const SideBar = ({ currentRoute }: { currentRoute: string }) => {
  const [isSelected, setIsSelected] = useState(
    currentRoute === "dashboard" ? "dashboard" : currentRoute.split("/")[1]
  );
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();

  if (isSelected === "logout") {
    localStorage.removeItem("token");
    navigate("/login");
  }

  useEffect(() => {
    setIsCollapsed(false);
  }, []);

  return (
    <div
      // onMouseEnter={() => setIsCollapsed(false)}
      // onMouseLeave={() => setIsCollapsed(true)}
      className={`shadow-lg bg-primary rounded-2xl my-10 mx-5 transition-all max-h-screen overflow-auto  py-5 px-2  ${
        isCollapsed ? "w-20" : "sm:w-6/12 lg:w-64"
      }  text-lightGray `}
    >
      <div className={"flex justify-center items-center p-3"}>
        <img src={logo} alt="logo" className="w-8/12  mt-5" />
        {/* <Logo className="w-auto  mt-5" /> */}
      </div>
      <div className="flex flex-col space-y-7 items-start  justify-start w-full px-4 pl-2 min-h-full mt-14">
        <NavItem
          isCollapsed={isCollapsed}
          name="admins"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "admins"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "admins"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "admins"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "admins  " ? 0.9 : 0.1
              }
            >
              <path d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z" />
              <path d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z" />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="users"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "users"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "users"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "users"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={!isCollapsed && isSelected === "users  " ? 0.9 : 0.1}
            >
              <path d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm6,13A6,6,0,0,0,6,23a1,1,0,0,0,2,0,4,4,0,0,1,8,0,1,1,0,0,0,2,0ZM18,8a4,4,0,1,1,4-4A4,4,0,0,1,18,8Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,18,2Zm6,13a6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0ZM6,8a4,4,0,1,1,4-4A4,4,0,0,1,6,8ZM6,2A2,2,0,1,0,8,4,2,2,0,0,0,6,2ZM2,15a4,4,0,0,1,4-4A1,1,0,0,0,6,9a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0Z" />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="addresses"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "addresses"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "addresses"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "addresses"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "addresses  " ? 0.9 : 0.1
              }
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z"
              />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="advertisements"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "advertisements"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "advertisements"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "advertisements"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "advertisements  " ? 0.9 : 0.1
              }
            >
              <path d="m3 11 18-5v12L3 14v-3z" />
              <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6" />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="cities"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "cities"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "cities"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "cities"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={!isCollapsed && isSelected === "cities" ? 0.9 : 0.1}
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z"
              />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="categories"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "categories"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "categories"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "categories"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "categories  " ? 0.9 : 0.1
              }
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M16.5,0c-4.206,0-7.5,1.977-7.5,4.5v2.587c-.483-.057-.985-.087-1.5-.087C3.294,7,0,8.977,0,11.5v8c0,2.523,3.294,4.5,7.5,4.5,3.407,0,6.216-1.297,7.16-3.131,.598,.087,1.214,.131,1.84,.131,4.206,0,7.5-1.977,7.5-4.5V4.5c0-2.523-3.294-4.5-7.5-4.5Zm5.5,12.5c0,1.18-2.352,2.5-5.5,2.5-.512,0-1.014-.035-1.5-.103v-1.984c.49,.057,.992,.087,1.5,.087,2.194,0,4.14-.538,5.5-1.411v.911ZM2,14.589c1.36,.873,3.306,1.411,5.5,1.411s4.14-.538,5.5-1.411v.911c0,1.18-2.352,2.5-5.5,2.5s-5.5-1.32-5.5-2.5v-.911Zm20-6.089c0,1.18-2.352,2.5-5.5,2.5-.535,0-1.06-.038-1.566-.112-.193-.887-.8-1.684-1.706-2.323,.984,.28,2.092,.435,3.272,.435,2.194,0,4.14-.538,5.5-1.411v.911Zm-5.5-6.5c3.148,0,5.5,1.32,5.5,2.5s-2.352,2.5-5.5,2.5-5.5-1.32-5.5-2.5,2.352-2.5,5.5-2.5ZM7.5,9c3.148,0,5.5,1.32,5.5,2.5s-2.352,2.5-5.5,2.5-5.5-1.32-5.5-2.5,2.352-2.5,5.5-2.5Zm0,13c-3.148,0-5.5-1.32-5.5-2.5v-.911c1.36,.873,3.306,1.411,5.5,1.411s4.14-.538,5.5-1.411v.911c0,1.18-2.352,2.5-5.5,2.5Zm9-3c-.512,0-1.014-.035-1.5-.103v-1.984c.49,.057,.992,.087,1.5,.087,2.194,0,4.14-.538,5.5-1.411v.911c0,1.18-2.352,2.5-5.5,2.5Z"
              />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="drivers"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "drivers"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "drivers"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "drivers"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "drivers  " ? 0.9 : 0.1
              }
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12,0A12,12,0,0,0,0,12v0c.59,15.905,23.416,15.89,24,0v0A12,12,0,0,0,12,0Zm0,2a10.01,10.01,0,0,1,8.878,5.4l-7.049,1.41a9.64,9.64,0,0,1-3.8,0L3.108,7.428A10.01,10.01,0,0,1,12,2ZM9,21.54a10.027,10.027,0,0,1-6.935-8.4l3.9.78a2.994,2.994,0,0,1,2.05,1.515l.624,1.153A3,3,0,0,1,9,18.014Zm6,0V18.014a3,3,0,0,1,.362-1.428l.624-1.154a3,3,0,0,1,2.05-1.514l3.9-.78A10.027,10.027,0,0,1,15,21.54Zm2.644-9.583a4.987,4.987,0,0,0-3.416,2.522L13.6,15.633a5.009,5.009,0,0,0-.6,2.381V21.95a10.126,10.126,0,0,1-2,0V18.014a5.009,5.009,0,0,0-.6-2.381L9.772,14.48a4.985,4.985,0,0,0-3.416-2.523l-4.314-.863a9.82,9.82,0,0,1,.324-1.775l7.272,1.454a11.629,11.629,0,0,0,4.583,0l7.406-1.481a9.845,9.845,0,0,1,.331,1.8Z"
              />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="products"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "products"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "products"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "products"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "products  " ? 0.9 : 0.1
              }
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M19.5,16c0,.553-.447,1-1,1h-2c-.553,0-1-.447-1-1s.447-1,1-1h2c.553,0,1,.447,1,1Zm4.5-1v5c0,2.206-1.794,4-4,4H4c-2.206,0-4-1.794-4-4v-5c0-2.206,1.794-4,4-4h1V4C5,1.794,6.794,0,9,0h6c2.206,0,4,1.794,4,4v7h1c2.206,0,4,1.794,4,4ZM7,11h10V4c0-1.103-.897-2-2-2h-6c-1.103,0-2,.897-2,2v7Zm-3,11h7V13H4c-1.103,0-2,.897-2,2v5c0,1.103,.897,2,2,2Zm18-7c0-1.103-.897-2-2-2h-7v9h7c1.103,0,2-.897,2-2v-5Zm-14.5,0h-2c-.553,0-1,.447-1,1s.447,1,1,1h2c.553,0,1-.447,1-1s-.447-1-1-1ZM14,5c0-.553-.447-1-1-1h-2c-.553,0-1,.447-1,1s.447,1,1,1h2c.553,0,1-.447,1-1Z"
              />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="orders"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "orders"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "orders"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "orders"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "orders  " ? 0.9 : 0.1
              }
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M19,10H7v-3h1.5c1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5H3.5C1.57,0,0,1.57,0,3.5s1.57,3.5,3.5,3.5h1.5v3c-2.757,0-5,2.243-5,5v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-2.757-2.243-5-5-5ZM2,3.5c0-.827,.673-1.5,1.5-1.5h5c.827,0,1.5,.673,1.5,1.5s-.673,1.5-1.5,1.5H3.5c-.827,0-1.5-.673-1.5-1.5Zm3,8.5h14c1.654,0,3,1.346,3,3v3H2v-3c0-1.654,1.346-3,3-3Zm14,10H5c-1.304,0-2.415-.836-2.828-2H21.828c-.413,1.164-1.524,2-2.828,2Zm-10-7c0-.552,.448-1,1-1s1,.448,1,1-.448,1-1,1-1-.448-1-1Zm-4,0c0-.552,.448-1,1-1s1,.448,1,1-.448,1-1,1-1-.448-1-1Zm8,0c0-.552,.448-1,1-1s1,.448,1,1-.448,1-1,1-1-.448-1-1Zm4,0c0-.552,.448-1,1-1s1,.448,1,1-.448,1-1,1-1-.448-1-1Z"
              />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="discounts"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "discounts"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "discounts"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "discounts"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "discounts  " ? 0.9 : 0.1
              }
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12,24c-1.626,0-3.16-.714-4.208-1.959-1.54,.177-3.127-.406-4.277-1.555-1.149-1.15-1.729-2.74-1.59-4.362-1.211-.964-1.925-2.498-1.925-4.124s.714-3.16,1.96-4.208c-.175-1.537,.405-3.127,1.555-4.277,1.15-1.151,2.737-1.73,4.361-1.59,.964-1.21,2.498-1.925,4.124-1.925s3.16,.714,4.208,1.959c1.542-.176,3.127,.406,4.277,1.555,1.149,1.15,1.729,2.74,1.59,4.362,1.211,.964,1.925,2.498,1.925,4.124s-.714,3.16-1.96,4.208c.175,1.537-.405,3.127-1.555,4.277-1.151,1.15-2.741,1.729-4.361,1.59-.964,1.21-2.498,1.925-4.124,1.925Zm-4.127-3.924c.561,0,1.081,.241,1.448,.676,.668,.793,1.644,1.248,2.679,1.248s2.011-.455,2.679-1.248c.403-.479,.99-.721,1.616-.67,1.034,.087,2.044-.28,2.776-1.012,.731-.731,1.1-1.743,1.012-2.776-.054-.624,.19-1.213,.67-1.617,.792-.667,1.247-1.644,1.247-2.678s-.455-2.011-1.247-2.678c-.479-.403-.724-.993-.67-1.617,.088-1.033-.28-2.045-1.012-2.776s-1.748-1.096-2.775-1.012c-.626,.057-1.214-.191-1.617-.669-.668-.793-1.644-1.248-2.679-1.248s-2.011,.455-2.679,1.248c-.404,.479-.993,.719-1.616,.67-1.039-.09-2.044,.28-2.776,1.012-.731,.731-1.1,1.743-1.012,2.776,.054,.624-.19,1.213-.67,1.617-.792,.667-1.247,1.644-1.247,2.678s.455,2.011,1.247,2.678c.479,.403,.724,.993,.67,1.617-.088,1.033,.28,2.045,1.012,2.776,.732,.732,1.753,1.098,2.775,1.012,.057-.005,.113-.007,.169-.007Zm1.127-12.076c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm6,6c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm-4.168,1.555l4-6c.307-.459,.183-1.081-.277-1.387-.461-.308-1.081-.182-1.387,.277l-4,6c-.307,.459-.183,1.081,.277,1.387,.171,.114,.363,.168,.554,.168,.323,0,.641-.156,.833-.445Z"
              />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="reports"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "reports"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "reports"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "reports"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "reports  " ? 0.9 : 0.1
              }
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M19.95,5.536l-3.485-3.485c-1.322-1.322-3.08-2.05-4.95-2.05H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V10.485c0-1.87-.728-3.627-2.05-4.95Zm-1.414,1.414c.318,.318,.587,.671,.805,1.05h-4.341c-.551,0-1-.449-1-1V2.659c.379,.218,.733,.487,1.05,.805l3.485,3.485Zm1.464,12.05c0,1.654-1.346,3-3,3H7c-1.654,0-3-1.346-3-3V5c0-1.654,1.346-3,3-3h4.515c.163,0,.325,.008,.485,.023V7c0,1.654,1.346,3,3,3h4.977c.015,.16,.023,.322,.023,.485v8.515Zm-4.5-6h-7c-1.378,0-2.5,1.122-2.5,2.5v2c0,1.378,1.122,2.5,2.5,2.5h7c1.378,0,2.5-1.122,2.5-2.5v-2c0-1.378-1.122-2.5-2.5-2.5Zm.5,4.5c0,.276-.224,.5-.5,.5h-7c-.276,0-.5-.224-.5-.5v-2c0-.276,.224-.5,.5-.5h7c.276,0,.5,.224,.5,.5v2ZM6,10c0-.552,.448-1,1-1h2c.552,0,1,.448,1,1s-.448,1-1,1h-2c-.552,0-1-.448-1-1Zm0-4c0-.552,.448-1,1-1h2c.552,0,1,.448,1,1s-.448,1-1,1h-2c-.552,0-1-.448-1-1Z"
              />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="reviews"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "reviews"
              ? "bg-white text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "reviews"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "reviews"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "reviews  " ? 0.9 : 0.1
              }
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m16 6a1 1 0 0 1 0 2h-8a1 1 0 0 1 0-2zm7.707 17.707a1 1 0 0 1 -1.414 0l-2.407-2.407a4.457 4.457 0 0 1 -2.386.7 4.5 4.5 0 1 1 4.5-4.5 4.457 4.457 0 0 1 -.7 2.386l2.407 2.407a1 1 0 0 1 0 1.414zm-6.207-3.707a2.5 2.5 0 1 0 -2.5-2.5 2.5 2.5 0 0 0 2.5 2.5zm-4.5 2h-6a3 3 0 0 1 -3-3v-14a3 3 0 0 1 3-3h12a1 1 0 0 1 1 1v8a1 1 0 0 0 2 0v-8a3 3 0 0 0 -3-3h-12a5.006 5.006 0 0 0 -5 5v14a5.006 5.006 0 0 0 5 5h6a1 1 0 0 0 0-2z"
              />
            </svg>
          }
        />

        <NavItem
          isCollapsed={isCollapsed}
          name="settings"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "settings"
              ? "bg-white text-black font-semibold"
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8" : "h-8 w-8 ml-2"
              } transition-all ease-in-out `}
              fill={
                !isCollapsed && isSelected === "settings"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "settings"
                  ? "yellow"
                  : "white"
              }
              viewBox="0 0 30 30"
              strokeWidth={
                !isCollapsed && isSelected === "settings" ? 0.9 : 0.1
              }
            >
              <path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
              <path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" />
            </svg>
          }
        />

        <NavItem
          isCollapsed={isCollapsed}
          name="logout"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "logout"
              ? "bg-white text-black font-semibold"
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8" : "h-8 w-8 ml-2"
              } transition-all ease-in-out `}
              fill={
                !isCollapsed && isSelected === "logout"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "logout"
                  ? "yellow"
                  : "white"
              }
              viewBox="0 0 30 30"
              strokeWidth={!isCollapsed && isSelected === "logout" ? 0.9 : 0.1}
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M11.476,15a1,1,0,0,0-1,1v3a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H7.476a3,3,0,0,1,3,3V8a1,1,0,0,0,2,0V5a5.006,5.006,0,0,0-5-5H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H7.476a5.006,5.006,0,0,0,5-5V16A1,1,0,0,0,11.476,15Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M22.867,9.879,18.281,5.293a1,1,0,1,0-1.414,1.414l4.262,4.263L6,11a1,1,0,0,0,0,2H6l15.188-.031-4.323,4.324a1,1,0,1,0,1.414,1.414l4.586-4.586A3,3,0,0,0,22.867,9.879Z"
              />
            </svg>
          }
        />
      </div>
    </div>
  );
};

export default SideBar;
