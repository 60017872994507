import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";
import {
  useAddCityMutation,
  useDeleteCityMutation,
  useEditCityMutation,
  useGetCitiesQuery,
} from "../../../app/services/city";

import { message, Modal, Space, Table } from "antd";
import { AddCityPayload, City } from "../../../types/city";
import { parseError } from "../../../utils/utils";
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { Language } from "../../../types/category";
import AddCity from "./AddCity";
import EditCity from "./EditCity";

export const Cities = () => {
  const [selectedCity, setSelectedCity] = useState<CityCellType | undefined>();
  interface CityCellType {
    index: number;
    name: Language[];
    _id: string;
  }

  const data: CityCellType[] = [];
  const columns: ColumnsType<CityCellType> = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Space className="mr-3">
            <DeleteOutlined
              key="delete"
              onClick={() => showDeleteConfirm(record._id)}
              style={{ color: "red" }}
            />
          </Space>
          <Space>
            <EditOutlined
              style={{ color: "#109DED" }}
              key="edit"
              onClick={() => {
                setisEditModal(true);
                setSelectedCity(record);
              }}
            />
          </Space>
        </>
      ),
      width: 100,
      fixed: "left",
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: Language[]) => name[0].value,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const { confirm } = Modal;
  const [isEditModal, setisEditModal] = useState(false);

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this city?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        deleteCity(id);
      },
      onCancel() {},
    });
  }
  const {
    isFetching: isFetchingCity,
    data: categoriesData,
    refetch,
  } = useGetCitiesQuery({
    page: currentPage,
    limit: 10,
  });

  const [
    editCity,
    {
      isLoading: isEditCityLoading,
      isSuccess: isEditCitySuccess,
      isError: isEditCityError,
      error: editCityError,
      reset: resetEditCity,
    },
  ] = useEditCityMutation();

  const [
    addCity,
    {
      isLoading: isAddCityLoading,
      isSuccess: isAddCitySuccess,
      isError: isAddCityError,
      error: addCityError,
      reset: resetAddCity,
    },
  ] = useAddCityMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    setisEditModal(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setisEditModal(false);
  };

  const [
    deleteCity,
    {
      isSuccess: isDeleteCitySuccess,
      isError: isDeleteCityError,
      error: deleteCityError,
      reset: resetDeleteCity,
    },
  ] = useDeleteCityMutation();

  useEffect(() => {
    if (isEditCitySuccess) {
      message.success("City updated successfully");
      resetEditCity();
    }
    if (isEditCityError) {
      message.error(parseError(editCityError));
      resetEditCity();
    }
    if (isAddCitySuccess) {
      message.success("City added successfully");
      resetAddCity();
    }
    if (isAddCityError) {
      message.error(parseError(addCityError));
      resetAddCity();
    }
    if (isDeleteCitySuccess) {
      message.success("City deleted successfully");
      resetDeleteCity();
    }
    if (isDeleteCityError) {
      message.error(parseError(deleteCityError));
      resetDeleteCity();
    }
  }, [
    isDeleteCitySuccess,
    isEditCitySuccess,
    isAddCitySuccess,
    isAddCityError,
    isEditCityError,
    isDeleteCityError,
  ]);

  useEffect(() => {
    refetch();
    handleOk();
  }, [isEditCitySuccess, isAddCitySuccess, isDeleteCitySuccess]);

  return (
    <>
      <div className=" p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-h-4 fill-white" />
        </button>
      </div>

      {categoriesData?.result !== undefined &&
        categoriesData?.result.map((city: City) => {
          data.push({
            index: data.length + 1,
            _id: city._id,
            name: city.name,
          });
        })}

      <Table
        scroll={{ x: 2000, y: 450 }}
        bordered
        columns={columns}
        pagination={false}
        dataSource={data}
        loading={isFetchingCity}
      />

      <Pagination
        itemsPerPage={10}
        totalItems={categoriesData?.count ? categoriesData.count : 20}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && isEditModal === false && (
        <Modal
          title="Add a new City"
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddCity
            isLoading={isAddCityLoading}
            addCity={(city: AddCityPayload) => addCity(city)}
            isSuccess={isAddCitySuccess}
            error={isAddCityError}
            isCanceled={handleCancel}
          />
        </Modal>
      )}

      <Modal
        title="Edit City"
        open={isEditModal}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={false}
      >
        <EditCity
          city={selectedCity}
          error={editCityError}
          isSuccess={isEditCitySuccess}
          isLoading={isEditCityLoading}
          isCanceled={() => setisEditModal(false)}
          editCity={(data) => editCity(data)}
        />
      </Modal>
    </>
  );
};
