import { useEffect, useState } from "react";
import { Modal } from "antd";
import "antd/dist/antd.css";
import { Driver, EditDriverPayload } from "../../../types/driver";
import { EditDriver } from "./editDriver";
import { EditOutlined } from "@ant-design/icons";

type DriverCardProps = {
  driver: Driver;
  edit: (driver: EditDriverPayload) => void;
  editSuccess: boolean;
};

export const DriverCard = (props: DriverCardProps) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
  }, [props.editSuccess]);

  return (
    <>
      <EditOutlined style={{ color: "#109DED" }} key="edit" onClick={showModal} />
      {visible === true && (
        <Modal
          title="Edit driver"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <EditDriver
            driver={props.driver}
            edit={props.edit}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
