import { Form, Input, Button, Spin, Upload, message, Select } from "antd";

import { Option } from "antd/lib/mentions";
import { AddProductPayload } from "../../../types/product";
import { useGetCategoriesQuery } from "../../../app/services/category";
import { Category } from "../../../types/category";
import { useGetSellersQuery } from "../../../app/services/user";
import { User } from "../../../types/user";
import TextArea from "antd/lib/input/TextArea";

type AddProductProp = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addProduct: (product: AddProductPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
};

const AddProduct = (props: AddProductProp): JSX.Element => {
  const token = localStorage.getItem("token");

  const { data: categoriesData } = useGetCategoriesQuery({});

  const { data: sellersData } = useGetSellersQuery({
    limit: Number.MAX_SAFE_INTEGER + 1,
  });

  const onChange = (value: string) => {
    console.log(value);
  };
  const onSearch = (value: string) => {
    console.log(value);
  };

  const onFinish = async (values: any) => {
    // let profilePicture = values?.profilePicture[0].response[0].Location;

    props.addProduct({
      unit: values.unit,
      category: values.category,
      stock: parseInt(values.stock.toString()),
      price: parseInt(values.price.toString()),
      unitNumber: parseInt(values.unitNumber.toString()),
      currency: values.currency,
      description: values.description,
      name: values.name,
      seller: values.seller,
      images: values.profilePicture.map(
        (item: any) => item.response[0].Location
      ),
    });
  };

  const [form] = Form.useForm();

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Name: "
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Descriptoin: "
          name="description"
          rules={[{ required: true, message: "description is required" }]}
        >
          <TextArea className="w-60 rounded-full" />
        </Form.Item>
        s
        <Form.Item
          label="Stock: "
          name="stock"
          rules={[{ required: true, message: "Stock is required" }]}
        >
          <Input type="number" className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Price: "
          name="price"
          rules={[{ required: true, message: "Price is required" }]}
        >
          <Input type="number" className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Category: "
          name="category"
          rules={[{ required: true, message: "Users is required" }]}
        >
          <Select
            showSearch
            placeholder="Select a Category"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full "
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {categoriesData?.result?.map((category: Category) => (
              <Option className="rounded-full" value={category._id}>
                {category.name[0].value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Unit: "
          name="unit"
          rules={[{ required: true, message: "Unit is required" }]}
        >
          <Select
            showSearch
            placeholder="Select a Unit"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full "
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option className="rounded-full" value={"single"}>
              Single
            </Option>
            <Option className="rounded-full" value={"pack"}>
              Pack
            </Option>
            <Option className="rounded-full" value={"kilo"}>
              Kilo
            </Option>
            <Option className="rounded-full" value={"carton"}>
              Carton
            </Option>
            <Option className="rounded-full" value={"set"}>
              Set
            </Option>
            <Option className="rounded-full" value={"dozen"}>
              Dozen
            </Option>
            <Option className="rounded-full" value={"case"}>
              Case
            </Option>
            <Option className="rounded-full" value={"packet"}>
              Packet
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Unit number: "
          name="unitNumber"
          rules={[{ required: true, message: "Price is required" }]}
        >
          <Input type="number" className="w-60 rounded-full" />
        </Form.Item>
        <Form.Item
          label="Currency: "
          name="currency"
          rules={[{ required: true, message: "Currency is required" }]}
        >
          <Select
            showSearch
            placeholder="Select a Currency"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full "
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option className="rounded-full" value={"iqd"}>
              IQD
            </Option>
            <Option className="rounded-full" value={"usd"}>
              USD
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Seller: "
          name="seller"
          rules={[{ required: true, message: "Users is required" }]}
        >
          <Select
            showSearch
            placeholder="Select a Seller"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full "
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {sellersData?.result?.map((seller: User) => (
              <Option className="rounded-full" value={seller._id}>
                {seller.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Picture is required" }]}
          name="profilePicture"
          label="Picture:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={"https://api.joomlachi.potan.co/upload"}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button className="w-60 rounded-full hover:text-primary hover:border-primary">
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-red-900"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
      </Form>
    </>
  );
};

export default AddProduct;
