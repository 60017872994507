import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { AdminCard } from "./adminCard";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";
import {
  useGetAdminsQuery,
  useAddAdminMutation,
  useDeleteAdminMutation,
  useEditAdminMutation,
} from "../../../app/services/admin";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import { message, Modal, Space } from "antd";
import AddAdmin from "./addAdmin";
import { AddAdminPayload, Admin } from "../../../types/admin";
import { parseError } from "../../../utils/utils";
import Table, { ColumnsType } from "antd/lib/table";

export const Admins = () => {
  interface AdminCellType {
    index: number;
    _id: string;
    username: string;
    email: string;
    password: string;
    roles: string[];
    isActive: boolean;
    isAdmin: boolean;
  }

  const columns: ColumnsType<AdminCellType> = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Space className="mr-3">
            <DeleteOutlined
              key="delete"
              onClick={() => showDeleteConfirm(record._id)}
              style={{ color: "red" }}
            />
          </Space>
          <Space className="ml-3">
            <AdminCard
              adminData={record}
              edit={editAdmin}
              editSuccess={isEditAdminSuccess}
            ></AdminCard>
          </Space>
        </>
      ),
      width: 100,
      fixed: "left",
    },

    {
      title: "index",
      dataIndex: "index",
      key: "index",
      width: 100,
    },

    {
      title: "username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "password",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "roles",
      dataIndex: "roles",
      key: "roles",
    },
    {
      title: "isActive",
      dataIndex: "isActive",
      key: "isActive",
    },
    {
      title: "isAdmin",
      dataIndex: "isAdmin",
      key: "isAdmin",
    },
  ];
  const { confirm } = Modal;
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const data: AdminCellType[] = [];

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this driver?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        deleteAdmin(id);
      },
      onCancel() {},
    });
  }
  const {
    isFetching: isFetchingAdmins,
    data: adminsData,

    refetch,
  } = useGetAdminsQuery({
    page: currentPage,
    limit: 10,
  });

  const [
    editAdmin,
    {
      isSuccess: isEditAdminSuccess,
      isError: isEditAdminError,
      error: editAdminError,
      reset: resetEditAdmin,
    },
  ] = useEditAdminMutation();

  // add admin mutation with errors

  const [
    addAdmin,
    {
      isLoading: isAddAdminLoading,
      isSuccess: isAddAdminSuccess,
      isError: isAddAdminError,
      error: addUserError,
      reset: resetAddAdmin,
    },
  ] = useAddAdminMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [
    deleteAdmin,
    {
      isSuccess: isDeleteAdminSuccess,
      isError: isDeleteAdminError,
      error: deleteAdminError,
      reset: resetDeleteAdmin,
    },
  ] = useDeleteAdminMutation();

  useEffect(() => {
    if (isEditAdminSuccess) {
      message.success("admin updated successfully");
      resetEditAdmin();
    }
    if (isEditAdminError) {
      message.error(parseError(editAdminError));
      resetEditAdmin();
    }

    if (isAddAdminSuccess) {
      message.success("admin added successfully");
      resetAddAdmin();
    }
    if (isAddAdminError) {
      message.error(parseError(addUserError));
      resetAddAdmin();
    }
    if (isDeleteAdminSuccess) {
      message.success("admin deleted successfully");
      resetDeleteAdmin();
    }
    if (isDeleteAdminError) {
      message.error(parseError(deleteAdminError));
      resetDeleteAdmin();
    }
  }, [
    isDeleteAdminSuccess,
    isEditAdminSuccess,
    isAddAdminSuccess,
    isAddAdminError,
    isDeleteAdminError,
  ]);

  useEffect(() => {
    refetch();
    handleOk();
    console.log(deleteAdminError);
  }, [isEditAdminSuccess, isAddAdminSuccess, isDeleteAdminSuccess]);

  return (
    <>
      <div className="  p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-h-4 fill-white" />
        </button>
      </div>

      {adminsData?.result !== undefined &&
        adminsData?.result.map((admin: Admin) => {
          data.push({
            index: data.length + 1,
            _id: admin._id,
            username: admin.username,
            email: admin.email,
            password: admin.password,
            roles: admin.roles,
            isActive: admin.isActive,
            isAdmin: admin.isAdmin,
          });
        })}

      <Table
        scroll={{ x: 2000, y: 450 }}
        columns={columns}
        dataSource={data}
        bordered
        loading={isFetchingAdmins}
        pagination={false}
      />

      <Pagination
        itemsPerPage={10}
        totalItems={adminsData?.count ? adminsData.count : 20}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new admin"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddAdmin
            isLoading={isAddAdminLoading}
            addAdmin={(admin: AddAdminPayload) => addAdmin(admin)}
            isSuccess={isAddAdminSuccess}
            error={isAddAdminError}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
