import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";
import {
  useAddOrderMutation,
  useCancelOrderMutation,
  useDeleteOrderMutation,
  useEditOrderMutation,
  useGetOrdersQuery,
} from "../../../app/services/order";
import { OrderCard } from "./OrderCard";
import { Button, Input, message, Modal, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Order, OrderProduct } from "../../../types/order";
// import { AddOrder } from "./addOrder";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AddOrder } from "./AddOrder";
import { User } from "../../../types/user";
import { Discount } from "../../../types/discount";
import { Address } from "../../../types/address";
import { StatusCell } from "../../../components/table/cells/StatusCell";

export const Orders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const {
    isFetching: isFetchingOrders,
    data: ordersData,
    refetch,
  } = useGetOrdersQuery({
    page: currentPage,
    limit: 10,
  });
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [cancelOrder, cancelResult] = useCancelOrderMutation();

  interface OrderCellType {
    index: number;
    _id: string;
    shortId: string;
    buyer: User;
    seller: User;
    products: any[];
    discount: Discount;
    status: string;
    exchangeRate: number;
    totalPriceIQD: number;
    totalPriceUSD: number;
    address: Address;
  }

  const columns: ColumnsType<OrderCellType> = [
    {
      title: "Action",
      key: "action",

      render: (text: string, record: any) => (
        <div className="flex items-center ">
          {/* <Space className="mr-3">
            <DeleteOutlined
              key="delete"
              onClick={() => showDeleteConfirm(record._id)}
              style={{ color: "red" }}
            />
          </Space> */}

          <Space className="ml-3">
            <OrderCard
              editSuccess={isEditOrderSuccess}
              order={record}
              edit={editOrder}
            ></OrderCard>
          </Space>
          <Space className="mr-3">
            <Button
              onClick={() => {
                showCancelConfirm(record._id);
              }}
              disabled={record.status === "canceled"}
              className={`border-none text-primary ${
                record.status === "canceled" && "text-gray-500"
              } bg-transparent`}
            >
              Cancel
            </Button>
          </Space>
        </div>
      ),
      width: 150,
      fixed: "left",
    },
    {
      title: "index",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "seller",
      dataIndex: "seller",
      key: "seller",
      render: (data: User) => data?.fullName,
    },
    {
      title: "buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (data: User) => data?.fullName,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <StatusCell
          refetch={() => refetch()}
          orderId={record._id}
          value={text}
          isCanceled={record.status === "canceled"}
        />
      ),
    },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
      render: (data: Address) => data?.title,
    },
    {
      title: "products",
      dataIndex: "products",
      key: "products",
      render: (products: OrderProduct[]) => (
        <>
          {products.map((product) => (
            <>
              <Tag color="blue">{product.item?.name}</Tag>
              <Tag color="red">#{product.quantity}</Tag>
            </>
          ))}
        </>
      ),
    },
    {
      title: "discount",
      dataIndex: "discount",
      key: "discount",
      render: (data: Discount) => data?.code,
    },
    {
      title: "totalPriceIQD",
      dataIndex: "totalPriceIQD",
      key: "totalPriceIQD",
      render: (data) => {
        return Math.round(data * 100) / 100 + "IQD";
      },
    },
    {
      title: "totalPriceUSD",
      dataIndex: "totalPriceUSD",
      key: "totalPriceUSD",
      render: (data) => {
        return Math.round(data * 100) / 100 + "$";
      },
    },
    {
      title: "Exchange rate",
      dataIndex: "exchangeRate",
      key: "exchangeRate",
    },
  ];

  const [data, setData] = useState<OrderCellType[] | undefined>(undefined);

  const [
    addOrder,
    {
      isSuccess: isAddOrderSuccess,
      isError: isAddOrderError,
      error: addError,
      reset: resetAddOrder,
    },
  ] = useAddOrderMutation();

  const [
    editOrder,
    {
      isSuccess: isEditOrderSuccess,
      isError: isEditOrderError,
      error: editError,
      reset: resetEditOrder,
    },
  ] = useEditOrderMutation();

  const [
    ,
    {
      isSuccess: isDeleteOrderSuccess,
      isError: isDeleteOrderError,
      reset: resetDeleteOrder,
    },
  ] = useDeleteOrderMutation();

  const { confirm } = Modal;

  // function showDeleteConfirm(id: string) {
  //   confirm({
  //     title: "Are you sure delete this product?",
  //     icon: <ExclamationCircleOutlined />,

  //     okText: "Yes",
  //     okType: "danger",
  //     cancelText: "No",
  //     cancelButtonProps: { className: "bg-white border-white" },
  //     okButtonProps: { className: "bg-white border-red-600" },

  //     onOk() {
  //       deleteOrder(id);
  //     },
  //     onCancel() {},
  //   });
  // }

  const showCancelConfirm = (id: string) => {
    let slaw = "";
    return confirm({
      title: "Please type the cancellation reason below.",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <Input
            onChange={(e) => {
              console.log(e.target.value);
              slaw = e.target.value;
            }}
          />
        </div>
      ),

      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        console.log(slaw + " slaw");
        cancelOrder({ id, cancellationReason: slaw });
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    if (isDeleteOrderSuccess) {
      message.success("Order deleted successfully");
      resetDeleteOrder();
      refetch();
    }
    if (isDeleteOrderError) {
      message.error("Something went wrong");
      resetDeleteOrder();
    } else if (isAddOrderSuccess) {
      message.success("Order added successfully!");
      refetch();
      resetAddOrder();
    } else if (isAddOrderError) {
      const errorObj: { errorMessage: string } =
        addError && "data" in addError
          ? (addError.data as { errorMessage: string })
          : { errorMessage: "Server error" };
      message.error(errorObj.errorMessage);
      refetch();
      resetAddOrder();
    } else if (isEditOrderError) {
      const errorObj: { errorMessage: string } =
        editError && "data" in editError
          ? (editError.data as { errorMessage: string })
          : { errorMessage: "Server error" };
      message.error(errorObj.errorMessage);
      refetch();
      resetEditOrder();
    } else if (isEditOrderSuccess) {
      message.success("Edit order success");
      refetch();
      resetEditOrder();
    } else if (cancelResult.isSuccess) {
      message.success("Cancel order success");
      refetch();
      cancelResult.reset();
    } else if (cancelResult.isError) {
      message.error("Cancel order error");
      refetch();
      cancelResult.reset();
    }
  }, [
    isDeleteOrderSuccess,
    isAddOrderSuccess,
    isAddOrderError,
    isEditOrderSuccess,
    isEditOrderError,
    cancelResult,
  ]);

  useEffect(() => {
    ordersData?.result !== undefined &&
      setData(
        ordersData.result.map((order: Order) => {
          return {
            index: ordersData.result.length + 1,
            shortId: order.shortId,
            _id: order._id,
            address: order.address,
            buyer: order.buyer,
            discount: order.discount,

            products: order.products,
            seller: order.seller,
            status: order.status,
            totalPriceIQD: order.totalPriceIQD,
            totalPriceUSD: order.totalPriceUSD,
            exchangeRate: order.exchangeRate,
          };
        })
      );
  }, [ordersData, isFetchingOrders]);

  return (
    <>
      <div className="  p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-h-4 fill-white" />
        </button>
      </div>

      <Table
        scroll={{ x: 2000, y: 450 }}
        bordered
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={isFetchingOrders}
      />
      <Pagination
        itemsPerPage={10}
        totalItems={ordersData?.count ? ordersData.count : 20}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible && (
        <Modal
          title="Add Order"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          centered={true}
        >
          <AddOrder addOrder={addOrder} isCanceled={handleCancel}></AddOrder>
        </Modal>
      )}
    </>
  );
};
