import { baseApi } from "./baseApi";
import qs from "query-string";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import {
  AddAdvertisementPayload,
  Advertisement,
  EditAdvertisementPayload,
} from "../../types/advertisement";

export const advertisementApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAdvertisements: build.query<
      PaginatedResponse<Advertisement>,
      PaginatedPayload<Advertisement>
    >({
      query: (payload) => ({
        url: `advertisements?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),

    getAdvertisement: build.query<Advertisement, string>({
      query: (id) => ({
        url: `advertisements/${id}`,
        method: "GET",
      }),
    }),

    addAdvertisement: build.mutation<Advertisement, AddAdvertisementPayload>({
      query: (payload) => ({
        url: `advertisements`,
        method: "POST",
        body: payload,
      }),
    }),

    editAdvertisement: build.mutation<Advertisement, EditAdvertisementPayload>({
      query: (payload) => ({
        url: `advertisements/${payload._id}`,
        method: "PATCH",
        body: {
          image: payload.image,
          order: payload.order,
        },
      }),
    }),

    deleteAdvertisement: build.mutation<Advertisement, string>({
      query: (id) => ({
        url: `advertisements/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAdvertisementQuery,
  useGetAdvertisementsQuery,
  useDeleteAdvertisementMutation,
  useAddAdvertisementMutation,
  useEditAdvertisementMutation,
} = advertisementApi;
