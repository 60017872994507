import { useEffect, useState } from "react";
import { message, Modal, Space } from "antd";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  useGetDiscountsQuery,
  useDeleteDiscountMutation,
  useAddDiscountMutation,
  useEditDiscountMutation,
} from "../../../app/services/discount";

import AddDiscount from "./addDiscount";
import { AddDiscountPayload } from "../../../types/discount";
import DiscountCard from "./discountCard";
import { parseError } from "../../../utils/utils";
import Table, { ColumnsType } from "antd/lib/table";
import { Product } from "../../../types/product";
import { Category } from "../../../types/category";
import { User } from "../../../types/user";
import { Pagination } from "../../../components/Pagination";

export const Discounts = () => {
  interface DiscountCellType {
    index: number;
    _id: string;
    code: string;
    amount: number;
    currency: string;
    isPercentage: boolean;
    expiryDate: string;
    numberOfDiscounts: number;
    products: Product[];
    categories: Category[];
    sellers: User[];
    applyToAll: boolean;
    users: User[];
    public: boolean;
  }

  const columns: ColumnsType<DiscountCellType> = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Space>
            <Space className="mr-3">
              <DeleteOutlined
                key="delete"
                onClick={() => showDeleteConfirm(record._id)}
                style={{ color: "red" }}
              />
            </Space>
            <Space className="ml-3">
              <DiscountCard
                editDiscount={editDiscount}
                discount={record}
                editSuccess={isEditDiscountSuccess}
              ></DiscountCard>
            </Space>
          </Space>
        </>
      ),
      width: 100,
      fixed: "left",
    },
    {
      title: "index",
      dataIndex: "index",
      key: "index",
      width: 100,
    },

    {
      title: "code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "currency",
      dataIndex: "currency",
      key: "currency",
    },

    {
      title: "expiryDate",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (expiryDate: string) => {
        return new Date(expiryDate).toLocaleDateString();
      },
    },
    {
      title: "number of discounts",
      dataIndex: "numberOfDiscounts",
      key: "numberOfDiscounts",
    },
    {
      title: "products",
      dataIndex: "products",
      key: "products",
      width: 200,
      render: (products: Product[]) =>
        products?.map((product) => product.name).join(", "),
    },
    {
      title: "categories",
      dataIndex: "categories",
      key: "categories",
      width: 200,

      render: (categories: Category[]) =>
        categories?.map((category) => category.name[0].value).join(", "),
    },
    {
      title: "sellers",
      dataIndex: "sellers",
      key: "sellers",
      width: 200,

      render: (sellers: User[]) => (
        <Space>{sellers?.map((seller) => seller.fullName).join(", ")}</Space>
      ),
    },
    {
      title: "users",
      dataIndex: "users",
      key: "users",
      width: 200,

      render: (users: User[]) => users?.map((user) => user.fullName).join(", "),
    },
    {
      title: "isPercentage",
      dataIndex: "isPercentage",
      key: "isPercentage",
      render: (isPercentage: boolean) =>
        isPercentage ? (
          <CheckCircleOutlined className=" fill-green-600 text-green-600 h-4 w-4" />
        ) : (
          <CloseCircleOutlined className=" fill-red-600 text-red-600 h-4 w-4" />
        ),
    },
    {
      title: "applyToAll",
      dataIndex: "applyToAll",
      key: "applyToAll",
      render: (applyToAll: boolean) => (
        <Space size="middle">
          {applyToAll ? (
            <CheckCircleOutlined className=" fill-green-600 text-green-600 h-4 w-4" />
          ) : (
            <CloseCircleOutlined className=" fill-red-600 text-red-600 h-4 w-4" />
          )}
        </Space>
      ),
    },

    {
      title: "public",
      dataIndex: "public",
      key: "public",
      render: (publicDiscount: boolean) => (
        <Space size="middle">
          {publicDiscount ? (
            <CheckCircleOutlined className=" fill-green-600 text-green-600 h-4 w-4" />
          ) : (
            <CloseCircleOutlined className=" fill-red-600 text-red-600 h-4 w-4" />
          )}
        </Space>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const { confirm } = Modal;
  const data: DiscountCellType[] = [];
  const {
    isFetching: isFetchingDiscount,
    data: discountsData,
    refetch,
  } = useGetDiscountsQuery({
    page: currentPage,
    limit: 10,
  });
  const [
    deleteDiscount,

    {
      isSuccess: isDeleteDiscountSuccess,
      isError: isDeleteDiscountError,
      error: deleteDiscountError,
      reset: resetDeleteDiscount,
    },
  ] = useDeleteDiscountMutation();

  const [
    addDiscount,
    {
      isLoading: isAddDiscountLoading,
      isSuccess: isAddDiscountSuccess,
      isError: isAddDiscountError,
      error: addDiscountError,
      reset: resetAddDiscount,
    },
  ] = useAddDiscountMutation();

  const [
    editDiscount,
    {
      isSuccess: isEditDiscountSuccess,
      isError: isEditDiscountError,
      error: editDiscountError,
      reset: resetEditDiscount,
    },
  ] = useEditDiscountMutation();
  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this discount?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        deleteDiscount(id);
      },
      onCancel() {},
    });
  }

  useEffect(() => {
    if (isAddDiscountSuccess) {
      message.success("Discount added successfully");
      refetch();
      handleCancel();
      resetAddDiscount();
    }
    if (isAddDiscountError) {
      message.error(parseError(addDiscountError));
      resetAddDiscount();
    }
    if (isEditDiscountSuccess) {
      message.success("Discount edited successfully");
      refetch();
      handleCancel();
      resetEditDiscount();
    }
    if (isEditDiscountError) {
      message.error(parseError(editDiscountError));
      resetEditDiscount();
    }
    if (isDeleteDiscountSuccess) {
      message.success("Discount deleted successfully");
      refetch();
      resetDeleteDiscount();
    }
    if (isDeleteDiscountError) {
      message.error(parseError(deleteDiscountError));
      resetDeleteDiscount();
    }
  }, [
    isAddDiscountSuccess,
    isAddDiscountError,
    isEditDiscountSuccess,
    isEditDiscountError,
    isDeleteDiscountSuccess,
    isDeleteDiscountError,

    refetch,
  ]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <div className="  p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-4 fill-white" />
        </button>
      </div>

      {discountsData?.result?.map((discount: any) => {
        data.push({
          index: data.length + 1,
          _id: discount._id,
          code: discount.code,
          amount: discount.amount,
          currency: discount.currency,
          isPercentage: discount.isPercentage,
          expiryDate: discount.expiryDate,
          numberOfDiscounts: discount.numberOfDiscounts,
          products: discount.products,
          categories: discount.categories,
          sellers: discount.sellers,
          applyToAll: discount.applyToAll,
          users: discount.users,
          public: discount.public,
        });
      })}

      <Table
        scroll={{ x: 2000, y: 450 }}
        columns={columns}
        pagination={false}
        dataSource={data}
        bordered
        loading={isFetchingDiscount}
      />

      <Pagination
        itemsPerPage={10}
        totalItems={discountsData?.count ? discountsData.count : 20}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new Discount"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddDiscount
            isLoading={isAddDiscountLoading}
            addDiscount={(Discount: AddDiscountPayload) =>
              addDiscount(Discount)
            }
            isSuccess={isAddDiscountSuccess}
            error={isAddDiscountError}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
