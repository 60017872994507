import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { AddressCard } from "./addressCard";
import { ReactComponent as Add } from "../../../assets/svgs/add.svg";
import {
  useGetAddressesQuery,
  useAddAddressMutation,
  useDeleteAddressMutation,
  useEditAddressMutation,
} from "../../../app/services/address";

import { message, Modal, Space } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Address,
  AddAddressPayload,
  Coordinates,
} from "../../../types/address";
import AddAddress from "./addAddress";
import { parseError } from "../../../utils/utils";
import { User } from "../../../types/user";

export const Addresses = () => {
  interface AddressCellType {
    index: number;
    _id: string;
    coordinates: Coordinates;
    description: string;
    title: string;
    user: User;
  }

  const columns: ColumnsType<AddressCellType> = [
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <>
          <Space className="mr-3">
            <DeleteOutlined
              style={{ color: "red" }}
              color="red"
              onClick={() => {
                showDeleteConfirm(record._id);
              }}
            />
          </Space>
          <Space className="ml-3">
            <AddressCard
              addressData={record}
              edit={editAddress}
              editSuccess={isEditAddressSuccess}
            ></AddressCard>
          </Space>
        </>
      ),
      width: 100,
      fixed: "left",
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: 100,
    },

    {
      title: "Coordinates",
      dataIndex: "coordinates",
      key: "coordinates",
      render: (coordinates: Coordinates) => (
        <div>
          {coordinates.longitude},{coordinates.latitude}
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",

      key: "description",
    },
    {
      title: "Title",
      dataIndex: "title",

      key: "title",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user: User) => {
        return user?.fullName;
      },
    },

    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },
  ];

  const data: AddressCellType[] = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const { confirm } = Modal;

  const { data: addressData, refetch } = useGetAddressesQuery({
    page: currentPage,
    limit: 10,
  });

  const [
    editAddress,
    {
      isSuccess: isEditAddressSuccess,
      isError: isEditAddressError,
      error: editAddressError,
      reset: resetEditAddress,
    },
  ] = useEditAddressMutation();

  const [
    addAddress,
    {
      isLoading: isAddAddressLoading,
      isSuccess: isAddAddressSuccess,
      isError: isAddAddressError,
      error: addAddressError,
      reset: resetAddAddress,
    },
  ] = useAddAddressMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [
    deleteAddress,
    {
      isSuccess: isDeleteAddressSuccess,
      isError: isDeleteAddressError,
      error: deleteAddressError,
      reset: resetDeleteAddress,
    },
  ] = useDeleteAddressMutation();

  useEffect(() => {
    if (isEditAddressSuccess) {
      message.success("Address updated successfully");
      resetEditAddress();
    }
    if (isEditAddressError) {
      message.error(parseError(editAddressError));
      resetEditAddress();
    }
    if (isAddAddressSuccess) {
      message.success("Address added successfully");
      resetAddAddress();
    }
    if (isAddAddressError) {
      message.error(parseError(addAddressError));
      resetAddAddress();
    }
    if (isDeleteAddressSuccess) {
      message.success("Address deleted successfully");
      resetDeleteAddress();
    }
    if (isDeleteAddressError) {
      message.error(parseError(deleteAddressError));
      resetDeleteAddress();
    }
  }, [
    isDeleteAddressSuccess,
    isEditAddressSuccess,
    isAddAddressSuccess,
    isAddAddressError,
    isDeleteAddressError,
    isEditAddressError,
  ]);

  useEffect(() => {
    refetch();
    handleOk();
    console.log(deleteAddressError);
  }, [isEditAddressSuccess, isAddAddressSuccess, isDeleteAddressSuccess]);

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this address?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        deleteAddress(id);
      },
      onCancel() {},
    });
  }

  return (
    <>
      <div className=" p-4">
        <button
          onClick={showModal}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <span className="mr-1 text-white">Add</span>
          <Add className="h-4 w-h-4 fill-white" />
        </button>
      </div>

      {addressData?.result !== undefined &&
        addressData?.result?.map((address: Address) => {
          data.push({
            index: data.length + 1,
            _id: address._id,
            coordinates: address.coordinates,
            description: address.description,
            title: address.title,
            user: address.user,
          });
        })}
      <Table
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 2000, y: 450 }}
        pagination={false}
      />

      <Pagination
        itemsPerPage={10}
        totalItems={addressData?.count ? addressData.count : 20}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new Address"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddAddress
            isLoading={isAddAddressLoading}
            addAddress={(address: AddAddressPayload) => addAddress(address)}
            isSuccess={isAddAddressSuccess}
            error={isAddAddressError}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};
